import React, { useState, useEffect } from "react";
import { uniqueData } from "../utility";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { visuallyHidden } from "@mui/utils";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import CustomIconButton from "../../src/components/CustomIconButton";
import { GoogleProfilesUsersList } from "../services/GoogleProfilesUsers";
import { CSVLink } from "react-csv";

// Dummy data need to Delete
const headers = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Age", key: "age" },
];

const data = [
  {
    firstName: "Warren",
    lastName: "Morrow",
    email: "sokyt@mailinator.com",
    age: "36",
  },
  {
    firstName: "Gwendolyn",
    lastName: "Galloway",
    email: "weciz@mailinator.com",
    age: "76",
  },
  {
    firstName: "Astra",
    lastName: "Wyatt",
    email: "quvyn@mailinator.com",
    age: "57",
  },
  {
    firstName: "Jasmine",
    lastName: "Wong",
    email: "toxazoc@mailinator.com",
    age: "42",
  },
  {
    firstName: "Brooke",
    lastName: "Mcconnell",
    email: "vyry@mailinator.com",
    age: "56",
  },
  {
    firstName: "Christen",
    lastName: "Haney",
    email: "pagevolal@mailinator.com",
    age: "23",
  },
  {
    firstName: "Tate",
    lastName: "Vega",
    email: "dycubo@mailinator.com",
    age: "87",
  },
  {
    firstName: "Amber",
    lastName: "Brady",
    email: "vyconixy@mailinator.com",
    age: "78",
  },
  {
    firstName: "Philip",
    lastName: "Whitfield",
    email: "velyfi@mailinator.com",
    age: "22",
  },
  {
    firstName: "Kitra",
    lastName: "Hammond",
    email: "fiwiloqu@mailinator.com",
    age: "35",
  },
  {
    firstName: "Charity",
    lastName: "Mathews",
    email: "fubigonero@mailinator.com",
    age: "63",
  },
];

/////

let _globalArray = [];
const RoleType = [
  { id: 0, name: "MANAGER" },
  { id: 1, name: "OWNER" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
var _profilesUsersList = [];

// import MenuItem from '@mui/material/MenuItem';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "S.no",
  },

  {
    id: "admin",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },

  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "pendingInvitation",
    numeric: false,
    disablePadding: false,
    label: "Invitation",
  },
  {
    id: "rolechange",
    numeric: false,
    disablePadding: false,
    label: "Action ( Update Role)",
  },
];

const headers1 = [
  { label: "Action", key: "rolechange" },
  { label: "Invitation", key: "pendingInvitation" },
  { label: "Role", key: "email" },
  { label: "Age", key: "role" },
  { label: "Name", key: "admin" },
  { label: "S.no", key: "id" },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

let raedMaskParam =
  "storeCode,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems";

const LocationWiseManageUsers = (route) => {
  var baseselectedRow = [];
  baseselectedRow = route.loc;
  console.log("baseselectedRow", baseselectedRow);
  const baseselectedAccountName = route.accountName;
  const backBtn = route.backBtn;

  const navigation = useNavigate();
  const [locationFetchData, setlocationFetchData] = useState([]);
  /// contain slected row object

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [nextPageAccessToken, setNextPageAccessToken] = React.useState("");
  const [stopNextRequest, setStopNextRequest] = React.useState(false);

  const [stopNextProfileRequest, setStopNextProfileRequest] =
    React.useState(false);
  const [profileData, setProfileData] = useState([]);

  const [isAddUser, setIsAddUser] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuLListOpen = Boolean(anchorEl);
  const [groupTextField, setGroupTextField] = useState("");

  const [isGroupCreated, setIsGroupCreated] = useState(false);
  // const [isGroupDialogOpen, setIsGroupDialogOpen] = React.useState(false);

  const [roleType, setRoleType] = React.useState([]);
  const [selectedCellId, setSelectedCellId] = React.useState(-1);

  const [isRoleUpdated, setIsRoleUpdated] = React.useState(false);
  const [selectedAdminName, setSelectedAdminName] = React.useState("");
  const [isRoleUpdatError, setIsRoleUpdatError] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [totalProfileUsersList, setTotalProfileUsersList] = useState([]);

  const location = useLocation();
  const csvReport = {
    data: locationFetchData,
    headers: headers1,
    filename: "locowiz.csv",
  };

  const getGoogleProfilesUsersList = (data) => {
    console.log("getGoogleProfilesUsersList", data.length);

    var tempArray = [];
    setIsLoading(true);

    // TO-DO we can use Dispatch here : Redux
    const googleProfilesUsersList1 = async (data) => {
      for (let i = 0; i < data.length; i++) {
        const result = await GoogleProfilesUsersList(data[i].name).then(
          (res) => {
            Array.prototype.push.apply(tempArray, res);
          }
        );
      }
    };
    console.log("_profilesUsersList1", tempArray);
    let response = googleProfilesUsersList1(data).then((res) => {
      let arr = uniqueData(tempArray, "name");
      datatModel(arr);
      setIsLoading(false);
    });

    //
  };

  const handlegTransferSelect = (event, rows) => {
    const {
      target: { value },
    } = event;
    setRoleType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row1", value);
    // setSelectedAccoutName(value);

    // getGLocation(localStorage.getItem("token"), value);
  };

  const updateRoleCellSelect = (event, rows) => {
    const {
      target: { value },
    } = event;
    setRoleType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row1", value);
    // setSelectedAccoutName(value);

    // getGLocation(localStorage.getItem("token"), value);
  };

  const showMenuList = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const hideMenuList = () => {
    setAnchorEl(null);
  };

  //   console.log("name==", location.state.name);

  const navigateScreen = (params) => {
    navigation("/addgooglelocation", {
      state: {
        name: location.state.name,
        token: "",
      },
    });
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {/* No item slected */}
          </Typography>
        )}

        {
          numSelected > 0 ? <></> : <></>
          // (
          //   <Tooltip title="Filter list">
          //     <IconButton>
          //       <FilterListIcon />
          //     </IconButton>
          //   </Tooltip>
          // )
        }
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event, name, row) => {
    if (event.target.checked) {
      const newSelected = locationFetchData.map((n) => n.name);
      setSelected(newSelected);
      setSelectedRow(locationFetchData);

      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log("newSelected", newSelected);
    setSelected(newSelected);
    setSelectedRow(row);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log("nextPageAccessToken", nextPageAccessToken);
    if (!stopNextRequest) nextPaggeGLocation(nextPageAccessToken);
  };

  const nextPaggeGLocation = async (token) => {};

  async function getGoogleUsersLocationWise(token) {
    let urlString =
      "https://mybusinessaccountmanagement.googleapis.com/v1/" +
      baseselectedRow.name +
      "/admins";

    console.log("token=", localStorage.getItem("token"));

    try {
      const res = await axios.get(urlString, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log(" Location Admin Profile", res.data);

      if ("admins" in res.data) {
        datatModel(res.data.admins);
      } else {
        datatModel([]);
      }
      datatModel(res.data);
      return res.data;
    } catch (e) {
      console.log("1234");
    }
  }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    getGoogleProfilesUsersList(baseselectedRow);
  }, []);
  useEffect(() => {
    //  getGoogleUsersLocationWise("");
  }, [profileData]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - locationFetchData.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(locationFetchData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [locationFetchData, order, orderBy, page, rowsPerPage]
  );

  // const { profileData, token } = route.params;
  // console.log("pp", location.state.selectdProfileItem.accountName);
  const datatModel = (responseData) => {
    let newData = responseData;
    console.log("newData12=", newData.length);

    const tempModel = [];
    for (let i = 0; i < newData.length; i++) {
      let dic = {};
      dic.id = i;
      let data = newData[i];
      console.log("data111=", data);

      const hasKey = "name" in data;
      if (hasKey) {
        dic.name = data.name;
      }

      const storeCode = "admin" in data;
      if (storeCode) {
        dic.admin = data.admin;
      }

      const name = "account" in data;
      if (name) {
        dic.account = data.account;
      }

      const phoneNumberKey = "role" in data;
      if (phoneNumberKey) {
        dic.role = data.role;
      }
      const isVerified = "pendingInvitation" in data;
      if (isVerified) {
        console.log("isVerified", data.pendingInvitation);
        dic.pendingInvitation = "Pending";
      } else {
        dic.pendingInvitation = "Accepted";
      }

      tempModel.push(dic);
      setlocationFetchData(tempModel);
      console.log("dic", tempModel);
    }
    ///setlocationFetchData(tempModel);
  };

  const transferDialogClose = () => {
    setOpen(false);
  };

  const handleAddUserClose = () => {
    setIsAddUser(false);
  };

  const handleRolUpdateCancelDialog = () => {
    setIsRoleUpdated(false);
  };
  const roleUpdateBtnClicked = () => {
    setIsRoleUpdated(false);

    console.log("selectedAdminName", selectedAdminName);
    roleChanged(selectedAdminName, roleType);
  };

  const handleRoleUpdateErrorDialogCancel = () => {
    setIsRoleUpdatError(false);
  };

  const handleAddUserBtnClicked = () => {
    addUserInProfile();
    setIsAddUser(false);
  };

  const btnSubmitClicked = async () => {
    if (personName.length == 0) {
      setOpen(true);
    } else {
      setOpen(false);
      setIsLoading(true);
      let ComapanyIds = [];
      for (let i = 0; i < selected.length; i++) {
        ComapanyIds.push(selected[i]);
      }
      // console.log("ComapanyIds", ComapanyIds.toString);

      let request = {
        destinationAccount: personName[0],
      };

      let urlString =
        "https://mybusinessaccountmanagement.googleapis.com/v1/" +
        ComapanyIds.toString() +
        ":transfer";
      // console.log("transferStr =", urlString);
      //
      try {
        const res = await axios.post(urlString, request, {
          headers: {
            Authorization: "Bearer " + location.state.token,
          },
        });
        // console.log("Transfer Data Response", res.data);
        setIsLoading(false);
        return res.data;
      } catch (e) {
        setIsLoading(false);
        return e;
      }
    }
    //
  };

  // Add user in  profile(location)
  const addUserInProfile = async () => {
    let request = {
      admin: groupTextField,
      // account: personName[0],
      role: roleType[0],
    };

    console.log("request", request);

    let urlString =
      "https://mybusinessaccountmanagement.googleapis.com/v1/" +
      personName[0] +
      "/admins";

    try {
      const res = await axios.post(urlString, request, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      setIsGroupCreated(true);
      // console.log("Group Creation : Object: ", res.data);

      return res.data;
    } catch (e) {
      // console.log("Group Creation Failed : Error: ", e);
      return e;
    }
  };
  //

  const addSingleBusiiness = () => {
    hideMenuList();
    navigateScreen();
  };

  const saveToCsvFile = async () => {};

  const deleteRole = async () => {
    console.log("123", selected);

    for (let i = 0; i < selected.length; i++) {
      console.log("row", selected[i]);
      const res_name = selected[i];
      let urlString =
        "https://mybusinessaccountmanagement.googleapis.com/v1/" + res_name;
      try {
        const res = await axios.delete(urlString, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log("Deleted user admin Successffully", res.data);

        // return res.data;
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  const SaveGoogleSheet = async () => {
    hideMenuList();

    console.log("123456", selectedRow);

    let arr = [];

    for (let i = 0; i < selectedRow.length; i++) {
      let data = {
        "Account Name": baseselectedAccountName,
        "Account Id": selectedRow[i].account,
        "Location Id": baseselectedRow[0].name,
        Contact: baseselectedRow[0].phoneNumbers,
        "Verification Status": baseselectedRow[0].verification,
        Store: baseselectedRow[0].storecode,
        Name: selectedRow[i].admin,
        Role: selectedRow[i].role,
        "Invitation Status": selectedRow[i].pendingInvitation,
      };

      arr.push(data);
    }

    console.log("arr123", arr);

    // Cut and Style Location

    axios
      .post(
        "https://sheet.best/api/sheets/f2512288-9a6f-4c19-b4ff-4960d9e8cbc7",
        arr
      )
      .then((response) => {
        console.log("saved to google success fully", response);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  const getGAdminProfile = async (token, pAccountName) => {
    let urlString =
      "https://mybusinessaccountmanagement.googleapis.com/v1/" +
      pAccountName +
      "/admins";
    try {
      const res = await axios.get(urlString, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      console.log("Admin Profile", res.data);
      if ("admins" in res.data) {
        datatModel(res.data.admins);
      } else {
        datatModel([]);
      }
      datatModel(res.data);
      return res.data;
    } catch (e) {}
  };

  const handleChange = (event, rows) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row", value);

    getGAdminProfile(localStorage.getItem("token"), value);
  };

  const addUserBtnClicked = () => {
    // console.log("createGroupClicked");
    setIsAddUser(true);
  };

  const addRoleMenu = (
    <div>
      <FormControl style={{ width: 200, marginLeft: 20, marginTop: 20 }}>
        <InputLabel id="demo-multiple-name-label">Choose a role</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          /// multiple
          value={roleType}
          onChange={handlegTransferSelect}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          // style={{ fontSize: 12 }}
        >
          {RoleType.map((item) => (
            <MenuItem
              key={item.id}
              value={item.name} // This will give the account name as a value
              style={getStyles(item.id, roleType, theme)}
              // style={{ fontSize: 12 }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  const updateRoleMenuCellClicked = (event, row) => {
    setSelectedCellId(row.id);
    setRoleType(event.target.textContent);
    setSelectedAdminName(row.name);
    setIsRoleUpdated(true);
    // roleChanged(row, event.target.textContent);
  };

  const roleChanged = async (name, role) => {
    const request = {
      role: role,
    };
    console.log("item12=", name + ":" + role);

    const updatemask = "role";
    let urlString =
      "https://mybusinessaccountmanagement.googleapis.com/v1/" +
      name +
      "?" +
      "updateMask=" +
      updatemask;

    try {
      const res = await axios.patch(urlString, request, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log("Role Updated successfully", res.data);

      return res.data;
    } catch (e) {
      setIsRoleUpdatError(true);
      console.log("Role Updataion unsuccessfull", e);
    }
  };

  const backButtonClicked = () => {
    console.log("backButtonClicked");
    setlocationFetchData([]);
    _profilesUsersList = [];
    {
      backBtn();
    }
    // navigation("/");
  };

  const updateRoleMenuCell = (row) => {
    let tmpRole = roleType;
    console.log("tmpRole", selectedCellId + "::" + row.id);
    /// to do call api and mainttain array or call API fetch locatiion
    if (tmpRole == undefined || tmpRole == "") {
      tmpRole = row.role;
    }
    if (selectedCellId != row.id) {
      tmpRole = row.role;
    }

    return (
      <div>
        <FormControl style={{ width: 200, marginLeft: 0, marginTop: 20 }}>
          <InputLabel id="demo-multiple-name-label">Choose a role</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            /// multiple
            value={tmpRole}
            // onChange={updateRoleCellSelect}
            input={<OutlinedInput label="Name" />}
            MenuProps={MenuProps}
            style={{ fontSize: 12 }}
          >
            {RoleType.map((item) => (
              <MenuItem
                key={item.id}
                value={item.name} // This will give the account name as a value
                //style={getStyles(item.id, roleType, theme)}
                style={{ fontSize: 12 }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };
  // console.log("222=", locationFetchData);
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "white",
        height: "100vh",
        width: "100%",
        screenLeft: "12344",
        // marginLeft: 240,
        marginLeft: 0,
      }}
    >
      <div style={{ marginTop: 10 }}>
        <CustomIconButton btnClick={backButtonClicked} variant="outlined">
          Back
        </CustomIconButton>
      </div>

      {/* <div
        style={{
          marginLeft: 0,
          boxSizing: "content-box",
          color: "white",
          marginTop: 0,
          fontSize: 30,
          fontFamily: "-moz-initial",
          backgroundColor: "#3386FF",
          height: 60,
          textIndent: 5,
        }}
      >
        <h1>{"location.state.selectdProfileItem.accountName"}</h1>
      </div> */}
      <div style={{ marginBottom: -80 }}>
        <div
          style={{
            width: 300,
            marginLeft: 20,
            color: "Black",
            /// marginBottom: -100,
            marginTop: 30,
            fontSize: 20,
            fontFamily: "-moz-initial",
          }}
        >
          <h1> Profile users:</h1>
          <span style={{ color: "#3386FF" }}>{baseselectedRow[0].title}</span>
        </div>
        <div style={{ color: "blue", marginTop: 10, marginLeft: 1100 }}>
          <CSVLink {...csvReport}>Export to CSV</CSVLink>
        </div>
        <div
          style={{
            width: 200,
            marginLeft: 750,
            color: "Black",
            marginBottom: -100,
            marginTop: 0,
            fontSize: 30,
            fontFamily: "-moz-initial",
          }}
        >
          {/* <Button onClick={addUserBtnClicked} variant="outlined">
            Add user
          </Button> */}
        </div>
        {isAddUser ? (
          <Dialog
            open={isAddUser}
            onClose={handleAddUserClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Add new user"}</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Allow others to help you manage business info and customer
                reviews.
              </DialogContentText>
            </DialogContent>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "50ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                style={{ marginLeft: 20, marginTop: 10 }}
                id="standard-basic"
                label="Enter email addresses"
                variant="standard"
                onChange={(e) => {
                  setGroupTextField(e.target.value);
                }}
              />
            </Box>
            {addRoleMenu}
            <DialogActions>
              <Button onClick={handleAddUserClose}>Cancel</Button>
              <Button onClick={handleAddUserBtnClicked} autoFocus>
                Create
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>

      <div
        style={{
          backgroundColor: "white",
          marginTop: 100,
          height: 400,
          width: "90%",
          marginLeft: 10,
        }}
      >
        <Box sx={{ width: "100%", marginLeft: 5 }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            {selected.length > 0 ? (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {menuItemList()}
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {/* {addBusinessMenuList()} */}
              </div>
            )}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick} // comment for single use
                  onRequestSort={handleRequestSort}
                  rowCount={locationFetchData.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    {
                      console.log("visibleRows", visibleRows);
                    }
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // uncomment iif we want selection feature
                        onClick={(event) =>
                          handleRowClick(event, row.name, row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        height={80}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }} align="right">
                          {row.admin}
                        </TableCell>
                        <TableCell align="left">{row.role}</TableCell>
                        <TableCell align="left">
                          {row.pendingInvitation}
                        </TableCell>

                        {/* {} To DO we will sttop showing on the basis of permision : we will check permission from when login intoo loccowiz system */}
                        <TableCell
                          align="left"
                          onClick={(event) =>
                            updateRoleMenuCellClicked(event, row)
                          }
                        >
                          {row.role != "PRIMARY_OWNER"
                            ? updateRoleMenuCell(row)
                            : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={locationFetchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </div>
      {/* {selected.length > 0 ? (
        <>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : null}

          <Dialog
            open={open}
            onClose={transferDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Transfer the Locations"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have not selected group from the list, Please select group
                for next step
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={transferDialogClose}>Ok</Button>
              {/* <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
      {/* </DialogActions>
          </Dialog>
        </>
      ) : null} } */}
      <div>
        {/* {console.log("isGroupCreated", isGroupCreated)} */}
        {isRoleUpdated ? (
          <Dialog
            open={isRoleUpdated}
            onClose={handleRolUpdateCancelDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Role"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Do you want to update role?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={roleUpdateBtnClicked}>Ok</Button>
              <Button onClick={handleRolUpdateCancelDialog}>Cancel</Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {isRoleUpdatError ? (
          <Dialog
            open={isRoleUpdatError}
            onClose={handleRoleUpdateErrorDialogCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please check your role or try again after some time.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRoleUpdateErrorDialogCancel}>Ok</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      <div>
        {isLoading ? (
          <>
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </Dialog>
          </>
        ) : null}
      </div>
    </div>
    // </div>
  );

  function menuItemList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={SaveGoogleSheet} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
            Save to google sheet
          </MenuItem>
          <MenuItem onClick={deleteRole} disableRipple>
            Delete user
          </MenuItem>
          <MenuItem onClick={saveToCsvFile} disableRipple>
            Save to csv file
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }

  function addBusinessMenuList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Manage users
        </Button>
        {/* <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={addSingleBusiiness} disableRipple>
            Add single Business
          </MenuItem>
        </StyledMenu> */}
      </div>
    );
  }
};

export default LocationWiseManageUsers;
