import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTheme } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import { Alert } from "@mui/material";
import Switch from "@mui/material/Switch";

import { visuallyHidden } from "@mui/utils";
import Menu from "@mui/material/Menu";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

import CustomIconButton from "../components/CustomIconButton";

import CustomButton from "./../components/CustomButton";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { uniqueData } from "../utility";
import CustomList from "../../src/components/CustomList";
import CreateProfileForm from "../components/CreateProfileForm";

async function getGoogleAttributeViaCategory() {
  const url =
    "https://mybusinessbusinessinformation.googleapis.com/v1/categories?regionCode=IN&languageCode=en&view=FULL";
  const res = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  });
  return res.data.categories;
}
let tempModel = [];

const ITEM_HEIGHT = 200;
const ITEM_PADDING_TOP = 8;
var _profilesUsersList = [];

// import MenuItem from '@mui/material/MenuItem';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "S.no",
  },

  {
    id: "title",
    numeric: false,
    disablePadding: false,
    label: "Business name",
  },
  {
    id: "storeCode",
    numeric: false,
    disablePadding: false,
    label: "Store Code",
  },
  {
    id: "storefrontAddress",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "latlng",
    numeric: false,
    disablePadding: false,
    label: "latlng",
  },
  {
    id: "websiteUri",
    numeric: false,
    disablePadding: false,
    label: "WebsiteUri",
  },
  {
    id: "phoneNumbers",
    numeric: false,
    disablePadding: false,
    label: "Primary Phone",
  },

  {
    id: "primarycategory",
    numeric: false,
    disablePadding: false,
    label: "Primary Category",
  },
  {
    id: "additionalcategories",
    numeric: false,
    disablePadding: false,
    label: "Additional Categories",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "From The Business",
  },
  {
    id: "labels",
    numeric: false,
    disablePadding: false,
    label: "Labels",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

let raedMaskParam =
  "storeCode,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems";

const GoogleBulkStoreCreate = (route) => {
  const [personAttributeName, setPersonAttributeName] = useState([]);
  let [selectedAdditinalCategoryList, setSelectedAdditinalCategoryList] =
    useState([]);
  //let [isRequestSuccess, setIsRequestSuccess] = useState(false);
  let [successRequestArray, setSuccessRequestArray] = useState("");
  let [selectedAttributeList, setSelectedAttributeList] = useState([]);

  const [locationFetchData, setlocationFetchData] = useState([]);
  const [_lCategoryAttribute, setLCategoryAttribute] = useState([]);

  const [requestSuccess, setIsRequestSuccess] = useState({
    isRequestSuccess: false,
    type: "0",
    text: "",
  });

  const memoizedCategories = useMemo(
    () => _lCategoryAttribute,
    [_lCategoryAttribute]
  );

  var baseselectedRow = [];
  baseselectedRow = route.loc;
  const datatModel = (responseData) => {
    let tempModel1 = [];
    console.log("responseData=1", responseData);
    let newData = responseData;

    for (let i = 0; i < newData.length; i++) {
      let dic = {};
      let dic2 = {};

      dic.id = i;
      let data = newData[i].location;

      // id: "address",

      // id: "websiteUri",

      // id: "primaryphone",

      // id: "primarycategory",

      // id: "additionalcategories",

      // id: "description",

      // id: "labels",

      const websiteUrl = "websiteUri" in data;
      if (websiteUrl) {
        dic.websiteUri = data.websiteUri;
      }

      const hasKey = "storefrontAddress" in data;
      if (hasKey) {
        dic.address = data.storefrontAddress;
      }

      const latlng = "latlng" in data;
      if (latlng) {
        dic.latlng = data.latlng;
      }
      const category = "categories" in data;
      if (category) {
        dic.categories = data.categories;
      }
      const profile = "profile" in data;
      if (profile) {
        dic.profile = data.profile;
      }

      const storeCode = "storeCode" in data;
      if (storeCode) {
        dic.storecode = data.storeCode;
      }
      const title = "title" in data;
      if (title) {
        dic.title = data.title;
      }

      const phoneNumberKey = "phoneNumbers" in data;
      if (phoneNumberKey) {
        dic.phoneNumbers = data.phoneNumbers.primaryPhone;
      }

      //tempModel1.push(dic); // Assuming tempModel is a global variable
      tempModel1.push(data);
    }
    // const uni = uniqueData(tempModel1, "name"); // Call uniqueData function with the correct parameters
    setlocationFetchData(tempModel1);

    setIsDataFetched(true);

    console.log("StoreCodeAndProfileId", tempModel1);
  };

  // storeCode(baseselectedRow);

  console.log("baseselectedRow", baseselectedRow);
  const baseselectedAccountName = route.accountName;
  const brandName = route.brandName;
  const backBtn = route.backBtn;
  const baseRequestType = route.requestType;

  const navigation = useNavigate();

  /// contain slected row object

  const [isLoading, setIsLoading] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isUpdateUrlCategory, setIsUpdateUrlCategory] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuLListOpen = Boolean(anchorEl);

  const [isActionTypeDialog, setIsActionTypeDialog] = useState(false);

  const [selectedRow, setSelectedRow] = React.useState([]);
  const [actionDialogState, setActionDialogState] = useState({
    isEnable: false,
    actionDialogTitle: "",
    text: "",
  });
  const { isEnable, actionDialogTitle, actionDialogText } = actionDialogState;

  const updateActionDialogState = (enable, title, msg) => {
    setActionDialogState({
      ...actionDialogState,
      isEnable: enable,
      actionDialogTitle: title,
      actionDialogText: msg,
    });
  };

  const location = useLocation();

  function storeCode() {
    let profileIds = [];
    const dictionary = {};
    for (let i = 0; i < baseselectedRow.length; i++) {
      const key1 = { id: baseselectedRow[i].name };
      dictionary[key1] = baseselectedRow[i].storecode;
    }
    console.log("dictionary", dictionary);
  }

  const showMenuList = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const hideMenuList = () => {
    setAnchorEl(null);
  };
  const navigateScreen = (params) => {
    navigation("/addgooglelocation", {
      state: {
        name: location.state.name,
        token: "",
      },
    });
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {/* No item slected */}
          </Typography>
        )}
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = locationFetchData.map((n) => n.storeCode);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
    // if (event.target.checked) {
    //   const newSelected = locationFetchData.map((n) => {
    //     // Additional code can be written here
    //     const storeCode = n.storeCode;
    //     // const otherProperty = n.someOtherProperty;

    //     // Do something with storeCode and otherProperty

    //     //  return storeCode; // Return the value you want to include in the newSelected array
    //   });
    //   console.log("11-", newSelected);
    //   setSelected(newSelected.storeCode);
    // }
    // setSelected([]);
  };

  const handleRowClick = (event, storeCode, row) => {
    const selectedIndex = selected.indexOf(storeCode);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, storeCode);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log("newSelected", newSelected);
    setSelected(newSelected);
    setSelectedRow(row);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log("nextPageAccessToken", nextPageAccessToken);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    console.log("useeffect called", locationFetchData);
    if (!isDataFetched) {
      datatModel(baseselectedRow);
    }
  }, [isDataFetched, locationFetchData]);

  const backButtonClicked = () => {
    console.log("back called");
    setlocationFetchData([]);
    baseselectedRow = undefined;
    // tempModel = undefined;
    setIsDataFetched(false);
    console.log("back called", locationFetchData);
    backBtn();
  };

  async function postUpdateAndSaveLater(data) {
    console.log("postUpdateAndSave", data);
    const request = data;
    console.log("23455", request);
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCOWIZ_URL + "/api/up-desc",
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Description updated successfully", response.data);
      return response.data;
    } catch (error) {
      console.log("Description update request failed", error);
      throw error;
    }
  }

  async function postUpdateAndSave(data) {
    console.log("postUpdateAndSave", data);
    const request = data;
    console.log("23455", request);
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCOWIZ_URL + "/api/up-desc",
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Description updated successfully", response.data);
      return response.data;
    } catch (error) {
      console.log("Description update request failed", error);
      throw error;
    }
  }

  const isSelected = (storeCode) => selected.indexOf(storeCode) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - locationFetchData.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(locationFetchData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [locationFetchData, order, orderBy, page, rowsPerPage]
  );

  const updateCategory = () => {
    setIsUpdateUrlCategory(true);
    console.log("selected", selectedRow);
    hideMenuList();
  };

  const categoryDialogClose = () => {
    setIsUpdateUrlCategory(false);
    setPersonAttributeName([]);
  };

  const saveAndUpdate = async () => {
    let brandID = baseselectedAccountName;
    switch (process.env.REACT_APP_RUN_ENVIORNMENT) {
      case "GBM":
        brandID = brandID;

        break;
      case "DCC":
        brandID = localStorage.getItem("DCC-BRANDID");
        break;

      default:
        break;
    }

    const locArray = baseselectedRow;
    const profile = {
      description: textFieldText,
    };

    for (let i = 0; i < locArray.length; i++) {
      const json = {
        storeCode: locArray[i].storecode,
        profileId: locArray[i].name,
        // branndId: baseselectedAccountName,
        brandName: brandName,
        profile: profile,
        status: "Requested",
        brand_id: brandID,
      };
      console.log("pri", json);
      try {
        await postUpdateAndSave(json);
      } catch (error) {
        console.log("Error saving category:", error);
      }
    }

    setIsUpdateUrlCategory(false);
  };
  const saveAndUpdateLater = async () => {
    let brandID = baseselectedAccountName;
    switch (process.env.REACT_APP_RUN_ENVIORNMENT) {
      case "GBM":
        brandID = brandID;

        break;
      case "DCC":
        brandID = localStorage.getItem("DCC-BRANDID");
        break;

      default:
        break;
    }

    const locArray = baseselectedRow;
    const profile = {
      description: textFieldText,
    };

    for (let i = 0; i < locArray.length; i++) {
      const json = {
        storeCode: locArray[i].storecode,
        profileId: locArray[i].name,
        //  branndId: baseselectedAccountName,
        brandName: brandName,
        profile: profile,
        status: "Requested",
        brand_id: brandID,
      };
      console.log("pri", json);
      try {
        await postUpdateAndSaveLater(json);
      } catch (error) {
        console.log("Error saving category:", error);
      }
    }
    setIsUpdateUrlCategory(false);
  };

  // const addStoresIntoGBP = async () => {
  //   console.log("selected=", selected);
  //   console.log("selectedRow=", selectedRow);

  //   const requests = locationFetchData.map((locationData) => {
  //     const url =
  //       "https://mybusinessbusinessinformation.googleapis.com/v1/" +
  //       baseselectedAccountName +
  //       "/locations";

  //     return axios.post(url, locationData, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     });
  //   });

  //   try {
  //     const responses = await Promise.all(requests);

  //     responses.forEach((res) => {
  //       if (res.status === 200) {
  //         console.log("Location added in to Account  ", res.data);
  //         setSuccessRequestArray.push(res.data.location.storeCode);
  //       } else {
  //         console.log("Error adding location to Account  ", res.data);
  //       }
  //     });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  const [loading, setLoading] = useState(false);
  const [successRequestCount, setSuccessRequestCount] = useState(0);

  const addStoresIntoGBP = async () => {
    try {
      setLoading(true); // Set loading to true when starting requests

      console.log("selected=", selected);
      console.log("selectedRow=", selectedRow);

      const requests = locationFetchData.map((locationData) => {
        const url =
          "https://mybusinessbusinessinformation.googleapis.com/v1/" +
          baseselectedAccountName +
          "/locations";

        return axios.post(url, locationData, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
      });

      const responses = await Promise.all(requests);
      let count = 0;

      let array = [];

      responses.forEach((res) => {
        if (res.status === 200) {
          console.log("Location added in to Account  ", res.data);
          count = count + 1;
          array.push(res.data.storeCode);
        } else {
          console.log("Error adding location to Account  ", res.data);
        }
      });

      console.log("All requests fulfilled successfully.");

      setSuccessRequestArray(array.join(", "));

      setSuccessRequestCount(count);
      setIsRequestSuccess({ isRequestSuccess: true, type: "success" });
    } catch (error) {
      console.log("code", error.response.status ?? "");
      if (
        (error.response.status ?? "") == "403" ||
        (error.response.status ?? "") == "401"
      ) {
        setIsRequestSuccess({ isRequestSuccess: true, type: "error" });
      }
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleActionSuccessDialog = () => {
    setIsActionTypeDialog(false);
    updateActionDialogState(false, "", "");
  };

  // create profile

  let [textFieldText, setTextFieldText] = useState("");

  const textClickCalled = (text, id) => {
    let arr = [];
    console.log("textClickCalled", id);
    switch (id) {
      case "111":
        setTextFieldText(text);
        break;

      default:
        break;
    }
  };
  let i = 0;
  console.log("34556", _lCategoryAttribute);
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "white",
        height: "100vh",
        width: "100%",
        screenLeft: "12344",
        // marginLeft: 240,
        marginLeft: 0,
      }}
    >
      <div style={{ marginTop: 50 }}>
        <CustomIconButton btnClick={backButtonClicked} variant="outlined">
          Back
        </CustomIconButton>
        {requestSuccess.isRequestSuccess}
        {requestSuccess.isRequestSuccess && requestSuccess.type === "error" ? (
          <Alert
            onClose={() => {
              setIsRequestSuccess(false);
            }}
            variant="filled"
            severity="error"
          >
            Auth Error, Please login again: click on GBP option and login-in.
          </Alert>
        ) : requestSuccess.isRequestSuccess &&
          requestSuccess.type === "success" ? (
          <Alert
            variant="filled"
            severity="success"
            onClose={() => {
              setIsRequestSuccess(false);
            }}
          >
            Successful request count: {successRequestCount} and store Ids:{" "}
            {successRequestArray}
          </Alert>
        ) : null}
      </div>
      {/* <div style={{ marginLeft: 900 }}>
        <CustomButton
          id="1212"
          btnClick={updateCategory}
          name="Update category"
        />
      </div> */}

      {/* <Button onClick={updateUrl}>Update attribute</Button> */}
      {isEnable ? (
        <Dialog
          open={isEnable}
          onClose={handleActionSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{actionDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {actionDialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleActionSuccessDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {isUpdateUrlCategory ? (
        <Dialog
          open={isUpdateUrlCategory}
          onClose={categoryDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Description update"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            Please select description.
          </DialogContent>

          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <CreateProfileForm
              funct={textClickCalled}
              data={{ place: "Type your Description name" }}
              id="111"
            />
          </Box>

          <DialogActions>
            <Button onClick={categoryDialogClose} autoFocus variant="contained">
              Cancel
            </Button>
            <Button onClick={saveAndUpdate} autoFocus variant="contained">
              Save and Update
            </Button>
            <Button onClick={saveAndUpdateLater} autoFocus variant="contained">
              Save and update later
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <div style={{ marginBottom: -80 }}>
        <div
          style={{
            width: 1000,
            marginLeft: 20,
            color: "Black",
            marginBottom: -100,
            marginTop: 30,
            fontSize: 20,
            fontFamily: "-moz-initial",
          }}
        >
          <h1> Group name: {brandName} </h1>
          {/* <span style={{ color: "#3386FF" }}>{baseselectedRow[0].title}</span> */}
        </div>
        <div
          style={{
            width: 200,
            marginLeft: 750,
            color: "Black",
            marginBottom: -100,
            marginTop: 50,
            fontSize: 30,
            fontFamily: "-moz-initial",
          }}
        ></div>
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginTop: 100,
          height: 400,
          width: "90%",
          marginLeft: 10,
        }}
      >
        <Box sx={{ width: "100%", marginLeft: 5 }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            {selected.length > 0 ? (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {menuItemList()}
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              ></div>
            )}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick} // comment for single use
                  onRequestSort={handleRequestSort}
                  rowCount={locationFetchData.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    {
                      console.log("visibleRows", visibleRows);
                      console.log("fettchdata", locationFetchData);
                      console.log("row123=", row.value);
                    }
                    const isItemSelected = isSelected(row.storeCode);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // uncomment iif we want selection feature
                        // onClick={(event) =>
                        //   handleRowClick(event, row.storeCode, row)
                        // }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        height={80}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {i++}
                        </TableCell>
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">{row.storeCode}</TableCell>
                        <TableCell align="left">
                          {row.storefrontAddress.addressLines[0]}
                          {row.storefrontAddress.addressLines[1]}
                          {row.storefrontAddress.locality}
                          {row.storefrontAddress.administrativeArea} {"-"}
                          {row.storefrontAddress.postalCode}
                        </TableCell>
                        <TableCell align="left">
                          {row.latlng.latitude} {row.latlng.longitude}
                        </TableCell>

                        <TableCell align="left">{row.websiteUri}</TableCell>
                        <TableCell align="left">
                          {row.phoneNumbers.primaryPhone}
                          {" , "}
                          {row.phoneNumbers.additionalPhones}
                        </TableCell>
                        <TableCell align="left">
                          {row.categories.primaryCategory.name}
                        </TableCell>

                        <TableCell align="left">
                          {row.categories.additionalCategories ? (
                            row.categories.additionalCategories.map(
                              (category, index) => (
                                <div key={index}>{category.name}</div>
                              )
                            )
                          ) : (
                            // Handle the case where additionalcategories is undefined or empty
                            <div>No additional categories</div>
                          )}
                        </TableCell>
                        {/* <TableCell align="left">
                          {row.profile.description}
                        </TableCell> */}

                        <TableCell align="left">{""}</TableCell>
                        <TableCell align="left">{row.labels}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={locationFetchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </div>
      <div>
        {isLoading ? (
          <>
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </Dialog>
          </>
        ) : null}
      </div>
      <div>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    </div>
    // </div>
  );

  function menuItemList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={addStoresIntoGBP} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
            Add stores in to GBP
          </MenuItem>

          {/* <MenuItem onClick={createActionUrlTyppe} disableRipple>
            Create new action type
          </MenuItem> */}
          {/* <MenuItem onClick={updateUrl} disableRipple>
            Update order url
          </MenuItem> */}
        </StyledMenu>
      </div>
    );
  }
};
export default GoogleBulkStoreCreate;
