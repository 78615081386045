import React, { useState, useEffect, useRef } from "react";

// import xlsx from "xlsx";
import * as XLSX from "xlsx";
import { uniqueData } from "../utility";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { visuallyHidden } from "@mui/utils";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import LocationWiseManageUsers from "../../src/Screen/LocationWiseManageUsers";
import PieChart from "./PieChart";

import googlecategories from "../GoogleCategory.json"; // Replace with the correct path

// import googleProfilesUsersList from "../services/GoogleProfilesUsers";

import {
  GoogleProfilesUsersList,
  DccProfilesUsersList,
} from "../../src/services/GoogleProfilesUsers";

import GoogleAttributeScreen from "../Screen/GoogleAttributeScreen";
import GoogleDataUpdateScreen from "../../src/Screen/GoogleDataUpdateScreen";
import GooglePlaceActionScreen from "../../src/Screen/GooglePlaceActionScreen";
import ProfileCategoryUpdate from "../Screen/ProfileCategoryUpdate";
import GoogleDescriptionUpdate from "../Screen/GoogleDescriptionUpdate";
import GoogleLabelUpdate from "../Screen/GoogleLabelUpdate";
import GooglePhoneUpdate from "../Screen/GooglePhoneUpdates";
import GoogleBulkStoreCreate from "../Screen/GoogleBulkStoreCreate";

import { Stack } from "react-bootstrap";
import { useContext } from "react";
import urlPathContext from "../Conntext/UrlPathContext";
import { useDispatch, useSelector } from "react-redux";

import { fetchGAccoount } from "../Redux/Action/GoogleBusinessActions";

import GoogleNameUpdate from "../../src/Screen/GoogleNameUpdate";

let _globalArray = [];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
var acc_Name = "";
let tempModel = [];
let tempModel1 = [];
let editProfileArray = [];
var _profilesUsersList = [];

// import MenuItem from '@mui/material/MenuItem';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "storecode",
    numeric: false,
    disablePadding: false,
    label: "Store Code",
  },
  {
    id: "phoneNumbers",
    numeric: false,
    disablePadding: false,
    label: "Contact Number",
  },
  {
    id: "websiteUri",
    numeric: false,
    disablePadding: false,
    label: "WebsiteUri",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "verification",
    numeric: false,
    disablePadding: false,
    label: "Verification Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

let raedMaskParam =
  "storeCode,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems";

const GoogleLocation = (route) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Initialize the ref

  const value = useContext(urlPathContext);
  console.log("urlValues", value);
  const [locationFetchData, setlocationFetchData] = useState([]);
  /// contain slected row object

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  var [selectedRow, setselectedRow] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [nextPageAccessToken, setNextPageAccessToken] = React.useState("");
  const [stopNextRequest, setStopNextRequest] = React.useState(false);

  const [profileData, setProfileData] = useState([]);

  const [isCreateGroup, setIsCreateGroup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuLListOpen = Boolean(anchorEl);
  const [groupTextField, setGroupTextField] = useState("");
  const [selectedAccoutName, setSelectedAccoutName] = useState("");
  const [baseAccountName, setBaseAccountName] = useState("");

  const [isGroupCreated, setIsGroupCreated] = useState(false);
  const [isTransferLocation, setIsTransferLocation] = useState(false);
  // const [isGroupDialogOpen, setIsGroupDialogOpen] = React.useState(false);
  const [transferPersonName, setTransferPersonName] = React.useState([]);
  const [isLocationWiseUserList, setIsLocationWiseUserList] =
    React.useState(false);

  const [profileStatus, setProfileStatus] = useState([]);
  const [uniqueProfileUsersList, setUniqueProfileUsersList] = useState([]);
  const [totalProfileUsersList, setTotalProfileUsersList] = useState([]);
  const [isAttributeEnable, setIsAttributeEnable] = useState(false);

  const [isProfileAttributeEnable, setIsProfileAttributeEnable] =
    useState(false);
  const [isProfileCategoryEnabled, setIsProfileCategoryEnabled] =
    useState(false);
  const [isProfileDescriptionEnabled, setIsProfileDescriptionEnabled] =
    useState(false);
  const [isGBulkCreationEnabled, setIsGBulkCreationEnabled] = useState(false);
  const [isProfileLabelEnabled, setIsProfileLabelEnabled] = useState(false);
  const [isProfilePhoneNumberEnabled, setIsProfilePhoneNumberEnabled] =
    useState(false);
  const [isProfileNameEnabled, setIsProfileNameEnabled] = useState(false);

  const [finalLocBulkData, setFinalLocBulkData] = useState([]);

  const [isProfileAllAttributeEnable, setIsProfileAllAttributeEnable] =
    useState(false);

  const [isProfilePlaceLinkEnable, setIsProfilePlaceLinkEnable] =
    useState(false);
  const [isBulkFileClicked, setIsBulkFileClicked] = useState(false);

  const location = useLocation();
  const fetchGAccountData = useSelector(
    (state) => state.googleBusinessReducer.payload
  );
  console.log("fetchGAccountData", fetchGAccountData);

  const showMenuList = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const hideMenuList = () => {
    setAnchorEl(null);
  };

  const getPendingProfiledata = () => {
    setAnchorEl(null);
    setIsAttributeEnable(true);
  };

  const deleteLocation = () => {
    setAnchorEl(null);
  };

  const profileAttributeData = () => {
    setAnchorEl(null);
    setIsProfileAttributeEnable(true);
  };
  const profileCategoryData = () => {
    setAnchorEl(null);
    setIsProfileCategoryEnabled(true);
  };
  const profileDescriptionData = () => {
    setAnchorEl(null);
    setIsProfileDescriptionEnabled(true);
  };

  const gBulkCreationData = () => {
    setAnchorEl(null);
    setIsGBulkCreationEnabled(true);
  };

  const profileLabelData = () => {
    setAnchorEl(null);
    setIsProfileLabelEnabled(true);
  };

  const profilePhoneNumberrData = () => {
    setAnchorEl(null);
    setIsProfilePhoneNumberEnabled(true);
  };

  const profileNameData = () => {
    setAnchorEl(null);
    setIsProfileNameEnabled(true);
  };
  const profileAllAttribute = () => {
    setAnchorEl(null);
    setIsProfileAllAttributeEnable(true);
  };

  const profilePlaceLinkData = () => {
    setAnchorEl(null);
    setIsProfilePlaceLinkEnable(true);
  };

  const navigateScreen = (params) => {
    switch (params) {
      case "1":
        navigation("/addgooglelocation", {
          state: {
            name: acc_Name,
            token: "",
          },
        });

        break;

      case "1":
        navigation("/addgooglelocation", {
          state: {
            name: acc_Name,
            token: "",
            data: "",
          },
        });

        break;
      case "2":
        navigation("/addgooglelocation", {
          state: {
            name: acc_Name,
            token: "",
            data: "",
          },
        });

        break;

      default:
        break;
    }
  };

  const navigatePendingScreen = (params) => {
    navigation("/", {
      state: {
        name: acc_Name,
        token: "",
      },
    });
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            No item slected
          </Typography>
        )}

        {
          numSelected > 0 ? <></> : <></>
          // (
          //   <Tooltip title="Filter list">
          //     <IconButton>
          //       <FilterListIcon />
          //     </IconButton>
          //   </Tooltip>
          // )
        }
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = locationFetchData.map((n) => n.name);
      setSelected(newSelected);
      setselectedRow(locationFetchData);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    console.log("eennt12=", row);

    setselectedRow((prevArray) => [...prevArray, row]);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    console.log("nextPageAccessToken", nextPageAccessToken);
    if (!stopNextRequest) nextPaggeGLocation(nextPageAccessToken);
  };

  const nextPaggeGLocation = async (token) => {
    return;
    console.log("next location = ", acc_Name);
    let urlString =
      "https://mybusinessbusinessinformation.googleapis.com/v1/" +
      acc_Name +
      "/locations?readMask=" +
      raedMaskParam +
      "&pageToken=" +
      token;
    // console.log("LocactionurlString = ", token + ":" + urlString);
    try {
      const res = await axios.get(urlString, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      console.log("Next=", res.data);
      // if ("nextPageToken" in res.data) {
      //   setNextPageAccessToken(res.data.nextPageToken);
      // }

      if ("locations" in res.data) {
        NextdatatModel(res.data.locations);
      } else {
        NextdatatModel([]);
      }

      if ("nextPageToken" in res.data) {
        setNextPageAccessToken(res.data.nextPageToken);
      } else {
        setStopNextRequest(true);
      }

      return res.data;
    } catch (e) {
      console.log("errorLocation", e);
    }
  };

  const getGLocation = async (token, type) => {
    console.log("1223", type, acc_Name);
    switch (type) {
      case "GBM":
        setIsLoading(true);

        let urlString =
          "https://mybusinessbusinessinformation.googleapis.com/v1/" +
          acc_Name +
          "/locations?readMask=" +
          raedMaskParam;
        if (token.length != 0) {
          urlString =
            "https://mybusinessbusinessinformation.googleapis.com/v1/" +
            acc_Name +
            "/locations?readMask=" +
            raedMaskParam +
            "&pageToken=" +
            token;
        }
        console.log("selectedAccoutName", acc_Name);

        // console.log("LocactionurlString = ", token + ":" + urlString);
        try {
          const res = await axios.get(urlString, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });

          console.log("res.data=", res.data);
          if ("locations" in res.data) {
            datatModel(res.data.locations);
          } else {
            datatModel([]);
            setIsLoading(false);
          }
          if ("nextPageToken" in res.data) {
            console.log("token12", res.data.nextPageToken);
            getGLocation(res.data.nextPageToken, "GBM");
            // setNextPageAccessToken(res.data.nextPageToken);
          } else {
            console.log("tempModel=", tempModel);
            setlocationFetchData(tempModel);
            verifiedProfile(tempModel);
            setIsLoading(false);
            console.log("editProfileArray=", editProfileArray);

            // filterByHaasgoogleUpdated(editProfileArray);

            // setStopNextRequest(true);
          }
          return res.data;
        } catch (e) {
          setIsLoading(false);
          console.log("errorLocation", e);
        }

        break;
      case "DCC":
        setIsLoading(true);
        const brandId = localStorage.getItem("DCC-BRANDID");
        //const brandId = data.brand.brand_id;
        console.log("brandIId", brandId);

        const result = await DccProfilesUsersList(brandId);

        //  const result = await DccProfilesUsersList("3");
        console.log("123=123", result.data);
        DDCDataModel(result.data);
        setlocationFetchData(tempModel);
        verifiedProfile(tempModel);
        setIsLoading(false);

        break;

      default:
        break;
    }
    // if (stopNextRequest) return;
  };

  // async function getGoogleProfile(token) {
  //   if (stopNextProfileRequest) {
  //     return;
  //   }
  //   let url = "https://mybusinessaccountmanagement.googleapis.com/v1/accounts";
  //   if (token.length != 0) {
  //     url =
  //       "https://mybusinessaccountmanagement.googleapis.com/v1/accounts" +
  //       "?pageToken=" +
  //       token;
  //   }

  //   console.log("token=33", localStorage.getItem("token"));

  //   try {
  //     await axios
  //       .get(url, {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       })
  //       .then(async (res) => {
  //         let dic = [];
  //         for (let i = 0; i < res.data.accounts.length; i++) {
  //           let data = res.data.accounts[i];
  //           _globalArray.push(data);
  //         }

  //         if ("nextPageToken" in res.data) {
  //           setnextProfileAccessToken(res.data.nextPageToken);
  //           await delay(1000);
  //           getGoogleProfile(res.data.nextPageToken);
  //         } else {
  //           console.log("_globalArray =", _globalArray);
  //           let arr = uniqueData(_globalArray, "name");
  //           setStopNextProfileRequest(true);
  //           setnextProfileAccessToken("");
  //           setProfileData(arr);
  //         }
  //         return res.data;
  //       })
  //       .catch((e) => e);
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // }
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [isfetch, setIsfetch] = useState(false);
  // DCC
  // useEffect(() => {
  //   if (isfetch && fetchGAccountData.length <= 0) {
  //     dispatch(fetchGAccoount(""));
  //   } else {
  //     setProfileData(fetchGAccountData.data);
  //     setIsfetch(false);
  //   }
  // }, [dispatch, fetchGAccountData, isfetch]);

  // GMB
  useEffect(() => {
    // if (
    //   localStorage.getItem("token") == "" ||
    //   localStorage.getItem("token") == undefined

    // )
    //   return;
    if (fetchGAccountData.length <= 0) {
      // dispatch(fetchGAccoount(""));
    } else {
      console.log("1232", fetchGAccountData);
      setProfileData(fetchGAccountData);
      // setIsfetch(false);
    }
  }, [dispatch, fetchGAccountData]);

  const getGoogleProfilesUsersList = (data, type) => {
    switch (type) {
      case "GBM":
        const getGoogleProfilesUsersList1 = async (data) => {
          for (let i = 0; i < data.length; i++) {
            const result = GoogleProfilesUsersList(data[i].name).then((res) => {
              // _profilesUsersList.push(res);
              Array.prototype.push.apply(_profilesUsersList, res);
            });
          }
        };
        let response = getGoogleProfilesUsersList1(data).then((res) => {
          console.log("_profilesUsersList", _profilesUsersList);
          setTotalProfileUsersList(_profilesUsersList);
          filterByProfilleAccountId(_profilesUsersList);
        });

        break;
      case "DCC":
        // const getDccProfilesUsersList1 = async (data) => {
        //   for (let i = 0; i < data.length; i++) {
        //     const result = DccProfilesUsersList(data[i].name).then((res) => {
        //       // _profilesUsersList.push(res);
        //       Array.prototype.push.apply(_profilesUsersList, res);
        //     });
        //   }
        // };
        // let response1 = getDccProfilesUsersList1(data).then((res) => {
        //   console.log("_profilesUsersList", _profilesUsersList);
        //   setTotalProfileUsersList(_profilesUsersList);
        //   filterByProfilleAccountId(_profilesUsersList);
        // });
        break;

      default:
        break;
    }
    // TO-DO we can use Dispatch here : Redux

    //
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - locationFetchData.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(locationFetchData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [locationFetchData, order, orderBy, page, rowsPerPage]
  );

  // const { profileData, token } = route.params;
  // console.log("pp", location.state.selectdProfileItem.accountName);
  const datatModel = (responseData) => {
    console.log("responseData=1", responseData);
    let newData = responseData;
    // const tempModel1 = [];
    for (let i = 0; i < newData.length; i++) {
      let dic = {};
      let dic2 = {};

      dic.id = i;
      let data = newData[i];
      editProfileArray.push(data);

      const websiteUrl = "websiteUri" in data;
      if (websiteUrl) {
        dic.websiteUri = data.websiteUri;
      }

      console.log("websiteUrl", websiteUrl);

      const hasKey = "storefrontAddress" in data;
      if (hasKey) {
        dic.address = data.storefrontAddress.locality;
      }
      const category = "categories" in data;
      if (category) {
        dic.categories = data.categories;
      }

      const storeCode = "storeCode" in data;
      if (storeCode) {
        dic.storecode = data.storeCode;
        dic2.storecode = data.storeCode;
      }

      const name = "name" in data;
      if (name) {
        dic.name = data.name;
        dic2.name = data.name;
      }
      const title = "title" in data;
      if (title) {
        dic.title = data.title;
      }
      const profile = "profile" in data;
      if (profile) {
        dic.profile = data.profile;
      }
      const label = "labels" in data;
      if (label) {
        dic.labels = data.labels;
      }
      // console.log("1234", data.labels);

      const phoneNumberKey = "phoneNumbers" in data;
      if (phoneNumberKey) {
        dic.phoneNumbers = data.phoneNumbers;
      }
      const isVerified = "metadata" in data;
      if (isVerified) {
        if (data.metadata.hasVoiceOfMerchant) {
          dic.verification = "Verified";
        } else {
          dic.verification = "UnVerified";
        }
      }

      tempModel.push(dic);
      tempModel1.push(dic2);
    }
    // setlocationFetchData(tempModel);
    console.log("StoreCodeAndProfileId");
  };
  const DDCDataModel = (responseData) => {
    let newData = responseData;
    //slet tempModel = [];

    for (let i = 0; i < newData.length; i++) {
      let dic = {
        id: i,
      };

      let res_data = newData[i];
      // Check if all_data is null, skip processing the item
      if (res_data.all_data === null) {
        continue;
      }
      try {
        console.log("1");
        const data = JSON.parse(res_data.all_data.replace(/\\/g, ""));
        console.log("2");
        editProfileArray.push(data);
        console.log("data12=", data);

        if (data.hasOwnProperty("websiteUri")) {
          dic.websiteUri = data.websiteUri;
        }

        console.log("websiteUrl", data.hasOwnProperty("websiteUri"));

        if (data.hasOwnProperty("storefrontAddress")) {
          dic.address = data.storefrontAddress.locality;
        }

        if (data.hasOwnProperty("categories")) {
          dic.categories = data.categories;
        }

        if (data.hasOwnProperty("storeCode")) {
          dic.storecode = data.storeCode;
        }

        if (data.hasOwnProperty("name")) {
          dic.name = data.name;
        }

        if (data.hasOwnProperty("title")) {
          dic.title = data.title;
        }

        if (data.hasOwnProperty("profile")) {
          dic.profile = data.profile;
        }

        if (data.hasOwnProperty("labels")) {
          dic.labels = data.labels;
        }
        // console.log("1234", data.labels);

        if (data.hasOwnProperty("phoneNumbers")) {
          dic.phoneNumbers = {
            primaryPhone: data.phoneNumbers.primaryPhone || undefined,
            additionalPhones: data.phoneNumbers.additionalPhones || undefined,
          };
        }

        if (data.hasOwnProperty("metadata")) {
          dic.verification = data.metadata.hasVoiceOfMerchant
            ? "Verified"
            : "Unverified";
        }

        tempModel.push(dic);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        // Handle or log the error as needed
        // You can choose to skip the item or perform any other error handling logic
        continue;
      }
    }

    console.log("StoreCodeAndProfileId", tempModel);
  };

  const NextdatatModel = (responseData) => {
    let newData = responseData;
    let tempModel = [];
    for (let i = 0; i < newData.length; i++) {
      let dic = {};
      dic.id = i;
      let data = newData[i];

      // const websiteUrl = "websiteUri" in data;
      // if (websiteUrl) {
      //   dic.websiteUri = data.websiteUri;
      // }

      // console.log("websiteUrl", websiteUrl);
      const hasKey = "storefrontAddress" in data;
      if (hasKey) {
        dic.address = data.storefrontAddress.locality;
      }

      const storeCode = "storeCode" in data;
      if (storeCode) {
        dic.storecode = data.storeCode;
      }

      const name = "name" in data;
      if (name) {
        dic.name = data.name;
      }

      const phoneNumberKey = "phoneNumbers" in data;
      if (phoneNumberKey) {
        dic.phoneNumbers = data.phoneNumbers.primaryPhone;
      }
      const isVerified = "metadata" in data;
      console.log("metadata", data.metadata.hasVoiceOfMerchant);
      if (isVerified) {
        if (data.metadata.hasVoiceOfMerchant) {
          dic.verification = "Verified";
        } else {
          dic.verification = "UnVerified";
        }
      }

      // tempModel.push(dic); // new code
      locationFetchData.push(dic); // old codee
    }

    setlocationFetchData(locationFetchData);
  };

  const transferDialogClose = () => {
    setOpen(false);
  };

  const handleCreateGroupClose = () => {
    setIsCreateGroup(false);
  };

  const handleGroupSuccessDialog = () => {
    setIsGroupCreated(false);
  };
  const handleCreateGroupBtnClicked = () => {
    createGroup();
    setIsCreateGroup(false);
  };

  const handleTransferLocationOk = () => {
    console.log("handleTransferLocationOk");
    setIsTransferLocation(false);
  };
  const handleTransferLocationCancel = () => {
    console.log("handleTransferLocationOk");
    setIsTransferLocation(false);
  };

  const handleTransferLocation = async () => {
    console.log("handleTransferLocationOk");
    setIsTransferLocation(false);

    if (transferPersonName.length == 0) {
      setOpen(true);
    } else {
      setOpen(false);
      setIsLoading(true);
      let ComapanyIds = [];
      for (let i = 0; i < selected.length; i++) {
        ComapanyIds.push(selected[i]);
      }
      // console.log("ComapanyIds", ComapanyIds.toString);

      let request = {
        destinationAccount: transferPersonName[0],
      };

      let urlString =
        "https://mybusinessaccountmanagement.googleapis.com/v1/" +
        ComapanyIds.toString() +
        ":transfer";
      // console.log("transferStr =", urlString);
      //
      try {
        const res = await axios.post(urlString, request, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        console.log("Transfer Data Response", res.data);
        setIsLoading(false);
        return res.data;
      } catch (e) {
        setIsLoading(false);
        return e;
      }
    }
  };
  // Create Group
  const createGroup = async () => {
    let request = {
      accountName: groupTextField,
      primaryOwner: "accounts/116892341967638937754",
      type: "LOCATION_GROUP",
    };

    let urlString =
      "https://mybusinessaccountmanagement.googleapis.com/v1/accounts";
    try {
      const res = await axios.post(urlString, request, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      setIsGroupCreated(true);
      // console.log("Group Creation : Object: ", res.data);

      return res.data;
    } catch (e) {
      // console.log("Group Creation Failed : Error: ", e);
      return e;
    }
  };
  //

  const filterByProfilleAccountId = (data) => {
    console.log("filterByProfilleAccountId", data);
    const uniqueArr = data.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.account === obj.account)
    );

    console.log("uniqueArr", uniqueArr);

    setUniqueProfileUsersList(uniqueArr);
  };

  const filterByHaasgoogleUpdated = (data) => {
    console.log("filterByProfilleAccountId1", data);
    const uniqueArr = data.filter(
      (obj, index, self) =>
        index === self.findIndex((o) => o.metadata.hasGoogleUpdated === "true")
    );

    console.log("filterByHaasgoogleUpdated", uniqueArr);

    // setUniqueProfileUsersList(uniqueArr);
  };

  const verifiedProfile = (data) => {
    var data1 = [];
    var data2 = [];
    console.log("per12", data);
    if (data.length > 0) {
      data.map((item) => {
        if (item.verification == "Verified") {
          data1.push(item);
        } else {
          data2.push(item);
        }
      });

      const veri = (data1.length * 100) / data.length;
      const unVveri = (data2.length * 100) / data.length;
      setProfileStatus([
        {
          verified: veri,
          unverify: unVveri,
        },
      ]);
      console.log("per1", profileStatus);
      // filterByNumber();
      // *****get profile filter on the basis of Enum  if dcc then dcc if gbm then google.******
      // getGoogleProfilesUsersList(data, "DCC");
      getGoogleProfilesUsersList(data, process.env.REACT_APP_RUN_ENVIORNMENT);
    }
  };

  const addSingleBusiiness = () => {
    //if (localStorage.getItem("parent_Brand") == undefined) hideMenuList();
    navigateScreen("1");
  };

  const addBulkBusiiness = () => {
    //if (localStorage.getItem("parent_Brand") == undefined) hideMenuList();
    setIsBulkFileClicked(true);
    // navigateScreen("2");
  };
  const fetchGoogleCategories = async () => {
    try {
      const response = await fetch("./GoogleCategory.json");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      console.log("jsonData:", jsonData);
      return jsonData;
    } catch (error) {
      console.error("Error fetching Google categories:", error);
      return [];
    }
  };

  async function getDisplayNameByName(jsonArray, targetName) {
    console.log("111=", jsonArray, targetName);
    for (let i = 0; i < jsonArray.length; i++) {
      if (jsonArray[i].displayName.toLowerCase() === targetName.toLowerCase()) {
        return jsonArray[i].name;
      }
    }
    // Return a default value or handle the absence of a match as needed
    return "Display Name Not Found";
  }

  const handleFileSelect = async (e) => {
    //const googlecategories = await fetchGoogleCategories();

    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Parse the sheet's data as needed
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const header = jsonData[0];

      // Initialize an array to store JSON objects
      const jsonObjects = [];

      // Loop through the data rows (skip the first row which is the header)
      for (let i = 1; i < jsonData.length; i++) {
        const dataRow = jsonData[i];
        const jsonObject = {};

        // Map data to JSON object with header keys
        for (let j = 0; j < header.length; j++) {
          // Use header[j] as the key in the JSON object
          jsonObject[header[j]] = dataRow[j];
        }

        // Add the JSON object to the array
        jsonObjects.push(jsonObject);
      }

      // Do something with the array of JSON objects
      console.log(jsonObjects);

      const finalLocData = [];
      for (let i = 0; i < jsonObjects.length; i++) {
        const row = jsonObjects[i];

        console.log("row", row);

        const p_category = row.PrimaryCategory;
        console.log("p_category", p_category);

        const addCat = row.AdditionalCategories;
        console.log("addCat", addCat);

        //const addCatgs = addCat.split(", ");
        const addCatgs = addCat.split(/,\s*/);
        console.log("addCatgs", addCatgs);
        let addArray = [];
        for (let i = 0; i < addCatgs.length; i++) {
          addArray.push({
            name: await getDisplayNameByName(
              googlecategories.categories,
              addCatgs[i]
            ),
          });
        }

        console.log("addArray", addArray);
        // console.log("Start Minutes:", sunday.startMinutes);
        // console.log("End Hours:", sunday.endHours);
        // console.log("End Minutes:", sunday.endMinutes);
        let sunday = undefined;
        let addphh = undefined;
        // let pN = {};
        if (row.Sundayhours != undefined) {
          sunday = extractTimeParts(row.Sundayhours);
        }

        let monday = undefined;

        // let pN = {};
        if (row.Mondayhours != undefined) {
          monday = extractTimeParts(row.Mondayhours);
        }
        let tuesday = undefined;

        // let pN = {};
        if (row.Tuesdayhours != undefined) {
          tuesday = extractTimeParts(row.Tuesdayhours);
        }
        let wednesday = undefined;

        // let pN = {};
        if (row.Wednesdayhours != undefined) {
          wednesday = extractTimeParts(row.Wednesdayhours);
        }

        let thursday = undefined;

        // let pN = {};
        if (row.Thursdayhours != undefined) {
          thursday = extractTimeParts(row.Thursdayhours);
        }
        let friday = undefined;

        // let pN = {};
        if (row.Fridayhours != undefined) {
          sunday = extractTimeParts(row.Fridayhours);
        }
        let saturday = undefined;

        // let pN = {};
        if (row.Saturdayhours != undefined) {
          saturday = extractTimeParts(row.Saturdayhours);
        }

        let pN;
        if (row.AdditionalPhones !== undefined) {
          const addphh = row.AdditionalPhones;
          let additionalPhones;

          if (typeof addphh === "string") {
            additionalPhones = addphh.split(", ");
          } else {
            // If AdditionalPhones is not a string, convert it to an array
            additionalPhones = [addphh.toString()];
          }

          pN = {
            primaryPhone: row.PrimaryPhone.toString(),
            additionalPhones,
          };
        } else {
          pN = {
            primaryPhone: row.PrimaryPhone.toString(),
          };
        }

        //const sunday = extractTimeParts(row.Sundayhours ?? null);
        // const monday = extractTimeParts(row.Mondayhours);
        //  const tuesday = extractTimeParts(row.Tuesdayhours);
        // const wednesday = extractTimeParts(row.Wednesdayhours);
        //const thursday = extractTimeParts(row.Thursdayhours);
        // const friday = extractTimeParts(row.Fridayhours);
        // const saturday = extractTimeParts(row.Saturdayhours);
        let store = row.StoreCode.toString();
        let labe = row.Labels;
        //const tmplabe = labe.split(",");
        const tmplabe = labe.split(/,\s*/);
        const add1 = row.Addressline1;
        const add2 = row.Addressline2 ?? "";
        const add3 = row.Addressline3 ?? "";
        console.log("add1", add1);
        console.log("add2", add2);

        finalLocData.push({
          location: {
            languageCode: row.Country,
            storeCode: store,
            title: row.BusinessName,
            phoneNumbers: pN,

            categories: {
              primaryCategory: {
                name: await getDisplayNameByName(
                  googlecategories.categories,
                  p_category
                ),
              },
              additionalCategories: addArray,
            },
            storefrontAddress: {
              regionCode: row.Country,
              postalCode: row.PostalCode.toString(),

              administrativeArea: row.AdministrativeArea.toUpperCase(),
              locality: row.Locality,
              addressLines: [add1.toString(), add2.toString(), add3.toString()],
            },
            websiteUri: row.Website,
            regularHours: {
              periods: [
                sunday
                  ? {
                      openDay: "SUNDAY",
                      openTime: {
                        hours: sunday.startHours,
                        minutes: sunday.startMinutes,
                      },
                      closeDay: "SUNDAY",
                      closeTime: {
                        hours: sunday.endHours,
                        minutes: sunday.endMinutes,
                      },
                    }
                  : null, // If 'sunday' is undefined, add 'null' or any other appropriate value

                monday
                  ? {
                      openDay: "MONDAY",
                      openTime: {
                        hours: monday.startHours,
                        minutes: monday.startMinutes,
                      },
                      closeDay: "MONDAY",
                      closeTime: {
                        hours: monday.endHours,
                        minutes: monday.endMinutes,
                      },
                    }
                  : null,
                tuesday
                  ? {
                      openDay: "TUESDAY",
                      openTime: {
                        hours: tuesday.startHours,
                        minutes: tuesday.startMinutes,
                      },
                      closeDay: "TUESDAY",
                      closeTime: {
                        hours: tuesday.endHours,
                        minutes: tuesday.endMinutes,
                      },
                    }
                  : null,
                wednesday
                  ? {
                      openDay: "WEDNESDAY",
                      openTime: {
                        hours: wednesday.startHours,
                        minutes: wednesday.startMinutes,
                      },
                      closeDay: "WEDNESDAY",
                      closeTime: {
                        hours: wednesday.endHours,
                        minutes: wednesday.endMinutes,
                      },
                    }
                  : null,
                thursday
                  ? {
                      openDay: "THURSDAY",
                      openTime: {
                        hours: thursday.startHours,
                        minutes: thursday.startMinutes,
                      },
                      closeDay: "THURSDAY",
                      closeTime: {
                        hours: thursday.endHours,
                        minutes: thursday.endMinutes,
                      },
                    }
                  : null,
                friday
                  ? {
                      openDay: "FRIDAY",
                      openTime: {
                        hours: friday.startHours,
                        minutes: friday.startMinutes,
                      },
                      closeDay: "FRIDAY",
                      closeTime: {
                        hours: friday.endHours,
                        minutes: friday.endMinutes,
                      },
                    }
                  : null,
                saturday
                  ? {
                      openDay: "SATURDAY",
                      openTime: {
                        hours: saturday.startHours,
                        minutes: saturday.startMinutes,
                      },
                      closeDay: "SATURDAY",
                      closeTime: {
                        hours: saturday.endHours,
                        minutes: saturday.endMinutes,
                      },
                    }
                  : null,
              ],
            },
            labels: tmplabe,
            latlng: {
              latitude: row.Latitude,
              longitude: row.Longitude,
            },

            profile: {
              description: row.Fromthebusiness,
            },
          },
          brandId: acc_Name,
        });
      }
      console.log("finalData:", finalLocData);
      setFinalLocBulkData(finalLocData);
      gBulkCreationData();
      /*
      for (let i = 0; i < finalLocData.length; i++) {
        console.log(
          "121",
          finalLocData[i].location,
          localStorage.getItem("token")
        );

        let url =
          "https://mybusinessbusinessinformation.googleapis.com/v1/" +
          finalLocData[i].brandId +
          "/locations";

        try {
          axios
            .post(url, finalLocData[i].location, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              if (res.status == 200) {
                console.log("Location added in to Account  ", res.data);
                return res.data;
              }
              console.log("Location added in to Account  ", res.data);
              return res.data;
            })
            .catch((e) => console.log("Error", e.code));
        } catch (error) {}
      }
      */
    };

    reader.readAsBinaryString(file);
  };

  function extractTimeParts(timeString) {
    console.log("timeString", timeString);
    // Split the timeString into two parts using the "-" delimiter
    const timeRanges = timeString.split("-");

    if (timeRanges.length === 2) {
      // Split the first time range into hours and minutes
      const startTime = timeRanges[0].split(":");
      // Split the second time range into hours and minutes
      const endTime = timeRanges[1].split(":");

      if (startTime.length === 2 && endTime.length === 2) {
        // Extract individual hours and minutes
        const startHours = parseInt(startTime[0], 10);
        const startMinutes = parseInt(startTime[1], 10);
        const endHours = parseInt(endTime[0], 10);
        const endMinutes = parseInt(endTime[1], 10);

        // Return the extracted values
        return {
          startHours,
          startMinutes,
          endHours,
          endMinutes,
        };
      }
    }

    // Return an empty object or handle invalid input as needed
    return {};
  }

  const showPendingfield = () => {
    hideMenuList();
    navigatePendingScreen();
  };

  const transferLocationClicked = async () => {
    setIsTransferLocation(true);
    hideMenuList();
  };
  const manageLocationUserList = async () => {
    setIsLocationWiseUserList(true);
    hideMenuList();
  };

  const handleChange = (event, rows) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("handleChange Called", baseAccountName);

    let na = rows.props.children;
    // setSelectedAccoutName(value);
    setBaseAccountName(na);
    setSelectedAccoutName(value);

    acc_Name = value;
    setStopNextRequest(false);
    tempModel = [];
    tempModel1 = [];
    setProfileStatus([]);

    setlocationFetchData([]);
    setUniqueProfileUsersList([]);
    setTotalProfileUsersList([]);
    // Diffrentiate on the basis of type enum if GBM the google iif DCC then Dcc Panel
    console.log("1223");

    // getGLocation("", process.env.REACT_APP_RUN_ENVIORNMENT);
  };

  const fetchProfiles = () => {
    getGLocation("", process.env.REACT_APP_RUN_ENVIORNMENT);
  };

  const handlegTransferSelect = (event, rows) => {
    const {
      target: { value },
    } = event;
    setTransferPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row1", value);
  };

  const createGroupClicked = () => {
    // console.log("createGroupClicked");
    setIsCreateGroup(true);
  };
  const backButtonClicked = () => {
    setIsLocationWiseUserList(false);
    setSelected([]);
    setselectedRow([]);
  };

  const atttributeBackBtnClicked = () => {
    setIsAttributeEnable(false);
    setSelected([]);
    setselectedRow([]);
  };

  const profileAtttributeBackBtnClicked = () => {
    setIsProfileAttributeEnable(false);
    setSelected([]);
    setselectedRow([]);
  };

  const profileCategorybackBtnClicked = () => {
    setIsProfileCategoryEnabled(false);
    setSelected([]);
    setselectedRow([]);
  };
  const profileDescriptionbackBtnClicked = () => {
    setIsProfileDescriptionEnabled(false);
    setSelected([]);
    setselectedRow([]);
  };

  const gBulkCreationbackBtnClicked = () => {
    setIsGBulkCreationEnabled(false);
    // setSelected([]);
    // setselectedRow([]);
    setFinalLocBulkData([]);
  };
  const profileLabelbackBtnClicked = () => {
    setIsProfileLabelEnabled(false);
    setSelected([]);
    setselectedRow([]);
  };
  const profilePhoneNbackBtnClicked = () => {
    setIsProfilePhoneNumberEnabled(false);
    setSelected([]);
    setselectedRow([]);
  };
  const profileNameBtnClicked = () => {
    setIsProfileNameEnabled(false);
    setSelected([]);
    setselectedRow([]);
  };

  const profileAllAtttributeBackBtnClicked = () => {
    setIsProfileAllAttributeEnable(false);
    setSelected([]);
    setselectedRow([]);
  };

  const profilePlaceLinkBackBtnClicked = () => {
    setIsProfilePlaceLinkEnable(false);
    setSelected([]);
    setselectedRow([]);
  };
  const addStoreClicked = () => {
    setIsBulkFileClicked(true);
    // Trigger the file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  // console.log("222=", locationFetchData);

  if (isAttributeEnable) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleDataUpdateScreen
        loc={selectedRow}
        accountName={selectedAccoutName}
        backBtn={atttributeBackBtnClicked}
      />
    );
  }

  if (isProfileNameEnabled) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleNameUpdate
        loc={selectedRow}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={profileNameBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isProfilePhoneNumberEnabled) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GooglePhoneUpdate
        loc={selectedRow}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={profilePhoneNbackBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isProfileLabelEnabled) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleLabelUpdate
        loc={selectedRow}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={profileLabelbackBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isProfileDescriptionEnabled) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleDescriptionUpdate
        loc={selectedRow}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={profileDescriptionbackBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isGBulkCreationEnabled) {
    // const rows = selectedRow;

    console.log("finalLocBulkData=", finalLocBulkData);
    return (
      <GoogleBulkStoreCreate
        loc={finalLocBulkData}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={gBulkCreationbackBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isProfileCategoryEnabled) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <ProfileCategoryUpdate
        loc={selectedRow}
        accountName={selectedAccoutName}
        brandName={baseAccountName}
        backBtn={profileCategorybackBtnClicked}
        requestType={"0"}
      />
    );
  }

  if (isProfileAttributeEnable) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleAttributeScreen
        loc={selectedRow}
        accountName={selectedAccoutName}
        backBtn={profileAtttributeBackBtnClicked}
        requestType={"0"}
      />
    );
  }
  if (isProfileAllAttributeEnable) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GoogleAttributeScreen
        loc={selectedRow}
        accountName={selectedAccoutName}
        backBtn={profileAllAtttributeBackBtnClicked}
        requestType={"1"}
      />
    );
  }

  if (isProfilePlaceLinkEnable) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <GooglePlaceActionScreen
        loc={selectedRow}
        accountName={selectedAccoutName}
        backBtn={profilePlaceLinkBackBtnClicked}
      />
    );
  }

  if (isLocationWiseUserList) {
    const rows = selectedRow;

    console.log("selectedRow=", rows);
    return (
      <LocationWiseManageUsers
        loc={selectedRow}
        accountName={baseAccountName}
        backBtn={backButtonClicked}
      />
    );
  }

  return (
    <div
      style={{
        backgroundColor: "white",
        color: "white",
        height: "100vh",
        width: "100%",
        screenLeft: "12344",
        // marginLeft: 240,
        marginLeft: 0,
      }}
    >
      {isBulkFileClicked ? (
        //<div>
        // <input type="file" accept=".csv" onChange={handleFileSelect} />
        <div>
          <input
            type="file"
            accept=".csv"
            ref={fileInputRef} // Assign the ref to the file input element
            style={{ display: "none" }}
            onChange={(e) => handleFileSelect(e)}
          />
          {/* <button onClick={addStoreClicked}>Choose CSV File</button> */}
        </div>
      ) : //s </div>
      null}
      {/* {profileData.length <= 0 ? (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </Dialog>
      ) : null} */}
      {profileStatus.length > 0 ? (
        <div
          style={{
            marginTop: 0,
            width: "100%",
            height: 300,
            //  backgroundColor: "gray",
          }}
        >
          <div
            style={{
              marginTop: 30,
              width: 300,
              height: 300,
              //   backgroundColor: "yellow",
            }}
          >
            <PieChart props={profileStatus} />
          </div>

          <div
            style={{
              // backgroundColor: "red",
              color: "black",
              height: "200",
              width: "50vh",
              marginTop: -280,

              marginLeft: 340,
              fontSize: 20,
            }}
          >
            {/* <div>
              <h1 style={{ fontSize: 22, color: "#3386FF" }}>
                Total Profiles: {totalProfileUsersList.length}
              </h1>
              <h1 style={{ fontSize: 20, color: "#3386FF" }}>
                Unique Groups:
                {uniqueProfileUsersList.length}
              </h1>
            </div> */}
          </div>
        </div>
      ) : null}
      <div style={{ marginBottom: -80 }}>
        <div
          style={{
            width: 300,
            marginLeft: 20,
            color: "Black",
            marginBottom: -100,
            marginTop: 50,
            fontSize: 20,
            fontFamily: "-moz-initial",
          }}
        >
          <h1> GBP panel </h1>
        </div>
        <div>
          {isLoading ? (
            <>
              <Dialog
                open={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress />
                </Box>
              </Dialog>
            </>
          ) : null}
        </div>

        <div
          style={{
            width: 200,
            marginLeft: 750,
            color: "Black",
            marginBottom: -100,
            marginTop: 50,
            fontSize: 30,
            fontFamily: "-moz-initial",
          }}
        >
          <Button onClick={createGroupClicked} variant="outlined">
            Create Group
          </Button>
        </div>
        {isCreateGroup ? (
          <Dialog
            open={isCreateGroup}
            onClose={handleCreateGroupClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Create Group"}</DialogTitle>

            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Choose a name that briefly but clearly identifies your group.
              </DialogContentText>
            </DialogContent>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="standard-basic"
                label="Group Name"
                variant="standard"
                onChange={(e) => {
                  setGroupTextField(e.target.value);
                }}
              />
            </Box>
            <DialogActions>
              <Button onClick={handleCreateGroupClose}>Cancel</Button>
              <Button onClick={handleCreateGroupBtnClicked} autoFocus>
                Create
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        <div
          style={{
            width: 200,
            marginLeft: 950,
            color: "Black",
            marginBottom: -100,
            marginTop: 55,
            fontSize: 30,
            fontFamily: "-moz-initial",
          }}
        >
          {/* <Button variant="outlined"> Group Details</Button> */}
          <Button onClick={fetchProfiles} variant="outlined">
            Fetch Profiles
          </Button>
        </div>

        <div>
          <FormControl style={{ width: 300, marginLeft: 350, marginTop: 50 }}>
            <InputLabel id="demo-multiple-name-label">Select Group</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              /// multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {profileData.map((item) => (
                <MenuItem
                  key={item.accountName}
                  value={item.name} // This will give the account name as a value
                  style={getStyles(item.accountName, personName, theme)}
                >
                  {item.accountName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          marginTop: 100,
          height: 400,
          width: "90%",
          marginLeft: 10,
        }}
      >
        <Box sx={{ width: "100%", marginLeft: 5 }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            {selected.length > 0 ? (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {menuItemList()}
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {addBusinessMenuList()}
              </div>
            )}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={locationFetchData.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    {
                      //  console.log("locationFetchData=", locationFetchData);
                    }
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.name, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        height={80}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {/* {row.name} */}
                          {row.title}
                        </TableCell>
                        <TableCell style={{ textAlign: "left" }} align="right">
                          {row.storecode}
                        </TableCell>
                        <TableCell align="left">
                          {row.phoneNumbers.primaryPhone}
                        </TableCell>
                        <TableCell align="left">{row.websiteUri}</TableCell>
                        <TableCell align="left">{row.address}</TableCell>

                        <TableCell align="left">{row.verification}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={locationFetchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </div>
      {selected.length > 0 ? (
        <>
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : null}

          <Dialog
            open={open}
            onClose={transferDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Transfer the Locations"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You have not selected group from the list, Please select group
                for next step
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={transferDialogClose}>Ok</Button>
              {/* <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
        </>
      ) : null}
      <div>
        {/* {console.log("isGroupCreated", isGroupCreated)} */}
        {isGroupCreated ? (
          <Dialog
            open={isGroupCreated}
            onClose={handleGroupSuccessDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Group Creation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Group created successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleGroupSuccessDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>

      <div>
        <Dialog
          open={isTransferLocation}
          onClose={handleTransferLocationOk}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Group Creation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Only businesses you own will be transferred. User permissions for
              these businesses will be updated.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" fontSize={"90"}>
              Transfer business to
            </DialogContentText>
          </DialogContent>

          {/* <DialogActions> */}
          <div>
            <FormControl
              style={{ width: 300, marginLeft: 20, marginBottom: 20 }}
            >
              <InputLabel id="demo-multiple-name-label">
                Select a business group
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                /// multiple
                value={transferPersonName}
                onChange={handlegTransferSelect}
                input={<OutlinedInput label="Name" />}
                MenuProps={MenuProps}
              >
                {profileData.map((item) => (
                  <MenuItem
                    key={item.accountName}
                    value={item.name} // This will give the account name as a value
                    style={getStyles(
                      item.accountName,
                      transferPersonName,
                      theme
                    )}
                  >
                    {item.accountName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ height: 100 }}>
            <Button
              style={{ marginTop: 20, marginLeft: 400 }}
              onClick={handleTransferLocationCancel}
            >
              Cancel
            </Button>

            <Button
              style={{ marginTop: -65, marginLeft: 500 }}
              onClick={handleTransferLocation}
            >
              Transfer
            </Button>
          </div>

          {/* </DialogActions> */}
        </Dialog>
      </div>
    </div>
    // </div>
  );

  function menuItemList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={transferLocationClicked} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
            Transfer locations
          </MenuItem>
          <MenuItem onClick={manageLocationUserList} disableRipple>
            {/* <FileCopyIcon /> */}
            Manage profiles
          </MenuItem>
          <Divider sx={{ my: 0.5 }} />
          <MenuItem onClick={getPendingProfiledata} disableRipple>
            {/* <ArchiveIcon /> */}
            Profile-updated version
          </MenuItem>
          <MenuItem onClick={profileAttributeData} disableRipple>
            {/* <ArchiveIcon /> */}
            Profile attributes
          </MenuItem>
          <MenuItem onClick={profileNameData} disableRipple>
            {/* <ArchiveIcon /> */}
            Update name
          </MenuItem>

          <MenuItem onClick={profileCategoryData} disableRipple>
            {/* <ArchiveIcon /> */}
            Update category
          </MenuItem>
          <MenuItem onClick={profileDescriptionData} disableRipple>
            {/* <ArchiveIcon /> */}
            Update Description
          </MenuItem>
          <MenuItem onClick={profileLabelData} disableRipple>
            {/* <ArchiveIcon /> */}
            Update label
          </MenuItem>
          <MenuItem onClick={profilePhoneNumberrData} disableRipple>
            {/* <ArchiveIcon /> */}
            Update phone number
          </MenuItem>
          <MenuItem onClick={profileAllAttribute} disableRipple>
            {/* <ArchiveIcon /> */}
            All attrribute for profile
          </MenuItem>

          <MenuItem onClick={profilePlaceLinkData} disableRipple>
            {/* <ArchiveIcon /> */}
            Profile place links
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }

  function addBusinessMenuList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Add Business
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          {baseAccountName != "" ? (
            <div>
              <MenuItem onClick={addSingleBusiiness} disabled disableRipple>
                {/* <Icon>star</Icon>; */}
                {/* <EditIcon /> */}
                Add single store
              </MenuItem>
              <MenuItem onClick={addStoreClicked} disableRipple>
                {/* <Icon>star</Icon>; */}
                {/* <EditIcon /> */}
                Add Stores
              </MenuItem>
            </div>
          ) : null}

          <MenuItem onClick={showPendingfield} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }
};

export default GoogleLocation;
