import React, { useState } from "react";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
// import * as React from 'react';
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import CustomButton from "./CustomButton";

const WeekdayPicker = (props) => {
  const [isMonday, setIsMonday] = useState(true);
  const [isSunday, setIsSunday] = useState(true);
  const [isTuesday, setIsTuesday] = useState(true);
  const [isWednesday, setIsWednesday] = useState(true);
  const [isThursday, setIsThursday] = useState(true);
  const [isFriday, setIsFriday] = useState(true);
  const [issaturday, setIssaturday] = useState(true);
  const [MH, setMH] = useState(0);
  const [MM, setMM] = useState(0);
  const [CMH, setCMH] = useState(0);
  const [CMM, setCMM] = useState(0);
  const [TH, setTH] = useState(0);
  const [TM, setTM] = useState(0);
  const [CTH, setCTH] = useState(0);
  const [CTM, setCTM] = useState(0);

  const [WH, setWH] = useState(0);
  const [WM, setWM] = useState(0);
  const [CWH, setCWH] = useState(0);
  const [CWM, setCWM] = useState(0);

  const [THH, setTHH] = useState(0);
  const [THM, setTHM] = useState(0);
  const [CTHH, setCTHH] = useState(0);
  const [CTHM, setCTHM] = useState(0);

  const [FH, setFH] = useState(0);
  const [FM, setFM] = useState(0);
  const [CFH, setCFH] = useState(0);
  const [CFM, setCFM] = useState(0);

  const [SH, setSH] = useState(0);
  const [SM, setSM] = useState(0);
  const [CSH, setCSH] = useState(0);
  const [CSM, setCSM] = useState(0);

  const [SUH, setSUH] = useState(0);
  const [SUM, setSUM] = useState(0);
  const [CSUH, setCSUH] = useState(0);
  const [CSUM, setCSUM] = useState(0);

  const padding = "20px";
  function handleChangeM() {
    setIsMonday(!isMonday);
    console.log("123", isMonday);
  }
  function handleChangeT() {
    setIsTuesday(!isTuesday);
    console.log("123", isTuesday);
  }
  function handleChangeW() {
    setIsWednesday(!isWednesday);
    console.log("123", isWednesday);
  }
  function handleChangeTH() {
    setIsThursday(!isThursday);
    console.log("123", isThursday);
  }
  function handleChangeF() {
    setIsFriday(!isFriday);
    console.log("123", isFriday);
  }
  function handleChangeS() {
    setIssaturday(!issaturday);
    console.log("123", issaturday);
  }
  function handleChangeSU() {
    setIsSunday(!isSunday);
    console.log("123");
  }
  function getvalueTOpen(e) {
    setTH(e.$H);
    setTM(e.$m);
  }
  function getvalueTClose(e) {
    setCTH(e.$H);
    setCTM(e.$m);
  }
  function getvalueWOpen(e) {
    setWH(e.$H);
    setWM(e.$m);
  }
  function getvalueWClose(e) {
    setCWH(e.$H);
    setCWM(e.$m);
  }
  function getvalueTHOpen(e) {
    setTHH(e.$H);
    setTHM(e.$m);
  }
  function getvalueTHClose(e) {
    setCTHH(e.$H);
    setCTHM(e.$m);
  }
  function getvalueFOpen(e) {
    setFH(e.$H);
    setFM(e.$m);
  }
  function getvalueFClose(e) {
    setCFH(e.$H);
    setCFM(e.$m);
  }
  function getvalueSOpen(e) {
    setSH(e.$H);
    setSM(e.$m);
  }
  function getvalueSClose(e) {
    setCSH(e.$H);
    setCSM(e.$m);
  }
  function getvalueSUOpen(e) {
    setSUH(e.$H);
    setSUM(e.$m);
  }
  function getvalueSUClose(e) {
    setCSUH(e.$H);
    setCSUM(e.$m);
  }
  function getvalueMOpen(e) {
    setMH(e.$H);
    setMM(e.$m);
  }
  function getvalueMClose(e) {
    setCMH(e.$H);
    setCMM(e.$m);
  }

  function makeregularhour() {
    let periods = [];

    if (!isMonday) {
      periods.push({
        openDay: "MONDAY",
        openTime: {
          hours: MH,
          minutes: MM,
        },
        closeDay: "MONDAY",
        closeTime: {
          hours: CMH,
          minutes: CMM,
        },
      });
    }

    if (!isTuesday) {
      periods.push({
        openDay: "TUESDAY",
        openTime: {
          hours: TH,
          minutes: TM,
        },
        closeDay: "TUESDAY",
        closeTime: {
          hours: CTH,
          minutes: CTM,
        },
      });
    }

    if (!isWednesday) {
      periods.push({
        openDay: "WEDNESDAY",
        openTime: {
          hours: WH,
          minutes: WM,
        },
        closeDay: "WEDNESDAY",
        closeTime: {
          hours: CWH,
          minutes: CWM,
        },
      });
    }

    if (!isThursday) {
      periods.push({
        openDay: "THURSDAY",
        openTime: {
          hours: THH,
          minutes: THM,
        },
        closeDay: "THURSDAY",
        closeTime: {
          hours: CTHH,
          minutes: CTHM,
        },
      });
    }

    if (!isFriday) {
      periods.push({
        openDay: "FRIDAY",
        openTime: {
          hours: FH,
          minutes: FM,
        },
        closeDay: "FRIDAY",
        closeTime: {
          hours: CFH,
          minutes: CFM,
        },
      });
    }

    if (!issaturday) {
      periods.push({
        openDay: "SATURDAY",
        openTime: {
          hours: SH,
          minutes: SM,
        },
        closeDay: "SATURDAY",
        closeTime: {
          hours: CSH,
          minutes: CSM,
        },
      });
    }

    if (!isSunday) {
      periods.push({
        openDay: "SUNDAY",
        openTime: {
          hours: SUH,
          minutes: SUM,
        },
        closeDay: "SUNDAY",
        closeTime: {
          hours: CSUH,
          minutes: CSUM,
        },
      });
    }

    console.log("234", periods);
    props.btnRegularNextClick([periods]);

    // Do something with the periods array here
  }
  const [value, setValue] = React.useState(dayjs("T15:30"));
  return (
    <div>
      <FormGroup>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeSU} color="primary" />}
            label="Sunday"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <div style={{ marginRight: "10px" }}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Open time"
                    ampm={false}
                    value={value}
                    onChange={(newValue) => getvalueSUOpen(newValue)}
                  />
                </DemoContainer>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <DemoContainer components={["TimePicker"]}>
                  <TimePicker
                    label="Close time"
                    ampm={false}
                    onChange={(newValue) => getvalueSUClose(newValue)}
                  />
                </DemoContainer>
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeM} color="primary" />}
            label="Monday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueMOpen(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueMClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeT} color="primary" />}
            label="Tuesday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueTClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueTClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeW} color="primary" />}
            label="Wednesday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueWOpen(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueWClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeTH} color="primary" />}
            label="Thursday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueTHOpen(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueTHClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeF} color="primary" />}
            label="Friday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueFOpen(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueFClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ display: "flex", flexDirection: "row", margin: padding }}>
          <FormControlLabel
            control={<Checkbox onChange={handleChangeS} color="primary" />}
            label="Saturday"
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Open time"
                ampm={false}
                onChange={(newValue) => getvalueSOpen(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker"]}>
              <TimePicker
                label="Close time"
                ampm={false}
                onChange={(newValue) => getvalueSClose(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
        <div style={{ margin: "30px", width: "300px" }}>
          <CustomButton id="111" btnClick={makeregularhour} name="Next" />
        </div>
      </FormGroup>
    </div>
  );
};
export default WeekdayPicker;
