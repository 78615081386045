import React from "react";
import axios from "axios";

let raedMaskParam =
  "storeCode,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems";

async function GooggleProfileData(profileId) {
  let urlString =
    "https://mybusinessbusinessinformation.googleapis.com/v1/" +
    profileId +
    ":getGoogleUpdated" +
    "?readMask=" +
    raedMaskParam;
  console.log("urlString=", urlString);

  try {
    const res = await axios.get(urlString, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log(" Profile Data1", res.data);

    return res.data;
  } catch (e) {
    console.log("1234");
  }
}

export default GooggleProfileData;
