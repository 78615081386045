import React, { useState } from "react";
import Box from "@mui/material/Box";

import { TextField, Button } from "@mui/material";

const MultilineTextField = (props) => {
  const onTextClick1 = (text, id) => {
    console.log("1234567");
    props.onTextClick(text, id);
  };
  return (
    <div style={{ flexDirection: "row", flex: 1, marginLeft: 30 }}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "35ch", height: "0" },
          // backgroundColor: "green",
          flexDirection: "row",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-textarea"
          label={props.title}
          placeholder="Placeholder"
          maxRows={4}
          multiline
          variant="standard"
          onChange={(e) => onTextClick1(e.target.value)}
        />
      </Box>
    </div>
  );
};

export default MultilineTextField;
