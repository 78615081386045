export const types = {
  FETCH_GOOGLE_ACCOUNT: "FETCH_GOOGLE_ACCOUNT",
  FETCH_GOOGLE_ACCOUNT_SUCCESS: "FETCH_GOOGLE_ACCOUNT_SUCCESS",
  FETCH_GOOGLE_ACCOUNT_FAILED: "FETCH_GOOGLE_ACCOUNT_FAILED",
  RESET_STATE_GOOGLE_ACCOUNT: "RESET_STATE_GOOGLE_ACCOUNT",

  FETCH_GOOGLE_PROFILE: "FETCH_GOOGLE_PROFILE",
  FETCH_GOOGLE_PROFILE_SUCCESS: "FETCH_GOOGLE_PROFILE_SUCCESS",
  FETCH_GOOGLE_PROFILLE_FAILED: "FETCH_GOOGLE_PROFILLE_FAILED",
};
