/// USE BUSINESS INFORMATION API accounts.locations for creations
import axios from "axios";
import { uniqueData } from "../utility";
let stopNextProfileRequest = false;
let _globalArray = [];
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getGoogleBusinesss = async (token) => {
  //console.log("ApI Call");
  // if (stopNextProfileRequest) {
  //   return _globalArray;
  // }
  let url = "https://mybusinessaccountmanagement.googleapis.com/v1/accounts";
  if (token.length != 0) {
    url =
      "https://mybusinessaccountmanagement.googleapis.com/v1/accounts" +
      "?pageToken=" +
      token;
  }

  ///console.log("token=33", localStorage.getItem("token"));

  try {
    return await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then(async (res) => {
        let dic = [];
        for (let i = 0; i < res.data.accounts.length; i++) {
          let data = res.data.accounts[i];
          _globalArray.push(data);
        }
        // console.log("123==123", res.data);

        if ("nextPageToken" in res.data) {
          await delay(10);
          return getGoogleBusinesss(res.data.nextPageToken);
        } else {
          let arr = uniqueData(_globalArray, "name");

          //console.log("New Array", arr);

          // stopNextProfileRequest = true;
          return arr;
        }
        // return res.data;
      })
      .catch((e) => e);
  } catch (e) {
    console.log("error", e);
  }
};

// This is foro fettching G account from our DCC panel
export const getDCCBusinesss = async (token) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LOCOWIZ_URL +
        "/api/dcc-accounts" +
        "?brandId=" +
        token,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("DCC Group fetched successfully", response.data);
    return response.data;
  } catch (error) {
    console.log("DCC Group fetched successfully", error);
    throw error;
  }
};

export function getGoogleProfiles(text, request) {
  let location = {
    query: text,
  };

  console.log("getGoogleBusiness called", location);
  let url =
    "https://mybusinessbusinessinformation.googleapis.com/v1/googleLocations:search";

  console.log("url=", url, "::", location);

  try {
    axios
      .post(url, location, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("Category found", res.data);
        return res.data;
      })
      .catch((e) => console.log("Error", e));
  } catch (error) {}
}
