import * as React from "react";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList, ListChildComponentProps } from "react-window";

let global_data = [];

// function renderRow(props) {
//   const { index, style } = props;

//   //Virtualized List
//   return (
//     <ListItem style={style} key={index} component="div" disablePadding>
//       <ListItemButton onclick={selectedItem}>
//         <ListItemText primary={global_data[index].location.title} />
//       </ListItemButton>
//     </ListItem>
//   );
// }

export default function CustomList(props) {
  let screenType = "";
  if ("screenType" in props) {
    screenType = props.screenType;
  }
  const dataModel = props.data;
  let dataCount = 0;
  console.log("dataModel", dataModel);
  if (dataModel) {
    dataCount = dataModel.length;
    global_data = dataModel;
    console.log("company details", global_data);
  }

  function renderRow(props) {
    const { index, style } = props;
    //Virtualized List
    if (screenType == "AdditionalCategory") {
      return (
        <ListItem style={style} key={index} component="div" disablePadding>
          <ListItemButton onClick={(item) => selectedItem2(global_data[index])}>
            <ListItemText primary={global_data[index].displayName} />
          </ListItemButton>
        </ListItem>
      );
    }

    if (screenType != "Category") {
      return (
        <ListItem style={style} key={index} component="div" disablePadding>
          <ListItemButton onClick={(item) => selectedItem(global_data[index])}>
            <ListItemText primary={global_data[index].location.title} />
          </ListItemButton>
        </ListItem>
      );
    } else {
      return (
        <ListItem style={style} key={index} component="div" disablePadding>
          <ListItemButton onClick={(item) => selectedItem2(global_data[index])}>
            <ListItemText primary={global_data[index].displayName} />
          </ListItemButton>
        </ListItem>
      );
    }
  }

  const selectedItem = (event) => {
    {
      props.onclick(event);
    }
  };

  const selectedItem2 = (event) => {
    {
      props.onclick(event);
    }
  };

  return (
    <div
      style={
        {
          // width: "100",
          // height: "100",
          // borderWidth: 1,
          // marginLeft: 30,
          // ...{ border: ".5px solid  gray" },
        }
      }
    >
      <FixedSizeList
        height={350}
        width={250}
        itemSize={46}
        itemCount={dataCount}
        overscanCount={5}
      >
        {renderRow}
      </FixedSizeList>
    </div>
  );
}
