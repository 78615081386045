// action creators

import { types } from "../Action/types";

export const fetchGAccoount = (action) => ({
  type: types.FETCH_GOOGLE_ACCOUNT,
  payload: action,
});

export const fetchGAccoountSuccess = (action) => ({
  type: types.FETCH_GOOGLE_ACCOUNT_SUCCESS,
  payload: action.payload,
});

export const fetchGAccoountFailed = (action) => ({
  type: types.FETCH_GOOGLE_ACCOUNT_FAILED,
  payload: action.payload,
});

// export const resetGAccountState = () => ({
//   type: types.RESET_STATE_GOOGLE_ACCOUNT,
// });
