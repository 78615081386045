import React, { useEffect } from "react";
import CreateProfileForm from "../components/CreateProfileForm";
import CustomButton from "../components/CustomButton";
import CustomIconButton from "../components/CustomIconButton";
import getGoogleCategory from "../services/GoogleBusiness";
import axios from "axios";
import { useState } from "react";
import CustomList from "../components/CustomList";
import Box from "@mui/material/Box";
import logo from "../asset/busdemo.png";
import { Row } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { useNavigate } from "react-router-dom";

var rootStyle = {
  //backgroundColor: "red",

  height: "100vh",
  width: "100%",
  // screenLeft: "12344",
  marginLeft: 0,
  bordrWidth: 10,
};
let mainDataList = [];
const CreateGoogleProfille = (props) => {
  const btnClick = props.btnClick;
  const [categoryList, setCategoryList] = useState([]);
  const [addcategoryList, setAddCategoryList] = useState([]);
  const [cateFieldTxt, setCateFieldTxt] = useState(false);
  const [addCateFieldTxt, setAddCateFieldTxt] = useState(false);
  let [selectedCategoryData, setSelectedCategoryData] = useState({});
  let [additionalCategoryData, setAdditionalCategoryData] = useState([]);
  let [textFieldText, setTextFieldText] = useState("");
  let updateText = "";

  const navigate = useNavigate();

  const handleDelete = (e) => {
    console.info("You clicked the delete icon.", e);
    const updatedChipData = additionalCategoryData.filter(
      (chip) => chip.name !== e
    );
    setAdditionalCategoryData(updatedChipData);
    console.log("updatedChipData", updatedChipData);
  };

  const textClickCalled = (text, id) => {
    let arr = [];
    console.log("textClickCalled", id);
    switch (id) {
      case "111":
        setTextFieldText(text);
        break;
      case "222":
        if (text.length > 0) {
          console.log("mainDataList", textFieldText);
          setCateFieldTxt(true);
          let itm = mainDataList.filter((item) => {
            const re = item.displayName
              .toLowerCase()
              .includes(text.toLowerCase());
            if (re) {
              console.log("its true");
              arr.push(item);
            }
          });

          console.log("arr=", arr);
          setCategoryList(arr);
        } else {
          setCateFieldTxt(false);
        }
        break;

      case "333":
        if (text.length > 0) {
          console.log("mainDataList", textFieldText);
          setAddCateFieldTxt(true);
          let itm = mainDataList.filter((item) => {
            const re = item.displayName
              .toLowerCase()
              .includes(text.toLowerCase());
            if (re) {
              console.log("its true");
              arr.push(item);
            }
          });

          console.log("arr=", arr);
          setAddCategoryList(arr);
        } else {
          setAddCateFieldTxt(false);
        }
        break;

      default:
        break;
    }
  };
  useEffect(() => {
    getGoogleCategory("");
  }, []);

  //  https://mybusinessbusinessinformation.googleapis.com/v1/categories?key=YOUR_API_KEY&regionCode=IN&languageCode=en
  const getGoogleCategory = (text) => {
    console.log("category = ", localStorage.getItem("token"));
    let url =
      "https://mybusinessbusinessinformation.googleapis.com/v1/categories?regionCode=IN&languageCode=en&view=FULL";
    try {
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          mainDataList = res.data.categories;
          setCategoryList(res.data.categories);
          console.log("Category found", res.data);
          return res.data;
        })
        .catch((e) => console.log("Error", e));
    } catch (error) {}
  };

  const selectedListItem = (data) => {
    setSelectedCategoryData(data);
    updateText = data.displayName;

    setCateFieldTxt(false);
  };

  const selectedAddlitonalListItem = (data) => {
    setAdditionalCategoryData(data);
    updateText = data.displayName;
    setAdditionalCategoryData([...additionalCategoryData, data]);

    setAddCateFieldTxt(false);
  };

  const btnClickCalled = (id) => {
    // TO DO need to change this   localStorage.getItem("parent_A")
    let request = {
      title: textFieldText,
      categories: {
        primaryCategory: { name: selectedCategoryData.name },
        addittionalCategory: additionalCategoryData,
      },
    };
    console.log("btnClickCalled=");

    btnClick(request);
  };
  const chipUI = (
    <Stack direction="row" spacing={1}>
      {additionalCategoryData.map((chip, index) => (
        <Chip
          key={index}
          label={chip.displayName}
          variant={chip.name}
          // onClick={() => handleDelete(chip.label)}
          onDelete={() => handleDelete(chip.name)}
        />
      ))}
    </Stack>
  );

  return (
    <div style={rootStyle}>
      <div>
        <Box
          component="img"
          sx={{
            height: 580,
            width: 400,
            marginTop: 10,
            marginLeft: 20,
          }}
          src={logo}
        />
      </div>
      <div
        style={{
          width: 700,
          fontSize: 30,
          marginTop: -650,
          marginLeft: 600,
          color: "#3386FF",
        }}
      >
        <h1>Start building your business Profile</h1>
      </div>
      <div
        style={{
          marginLeft: 600,
          width: 800,
          height: 550,
          marginTop: 20,
          // backgroundColor: "green",

          borderWidth: 1,
          ...{ border: ".5px solid gray" },
        }}
      >
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Type your business name" }}
          id="111"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Type your category" }}
          id="222"
          textValue={" 7987"}
        />

        {cateFieldTxt ? (
          <div
            style={{
              marginLeft: 40,
              width: 350,
              height: 350,
              marginTop: -10,
              backgroundColor: "#3386FF",

              borderWidth: 1,
              ...{ border: ".5px solid gray" },
            }}
          >
            <CustomList
              data={categoryList}
              onclick={selectedListItem}
              screenType="Category"
            />
          </div>
        ) : null}

        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Add addional category" }}
          id="333"
          textValue={" 7987"}
        />
        {addCateFieldTxt ? (
          <div
            style={{
              marginLeft: 40,
              width: 350,
              height: 350,
              marginTop: -10,
              backgroundColor: "#3386FF",

              borderWidth: 1,
              ...{ border: ".5px solid gray" },
            }}
          >
            <CustomList
              data={addcategoryList}
              onclick={selectedAddlitonalListItem}
              screenType="AdditionalCategory"
            />
          </div>
        ) : null}
        {chipUI}

        <div style={{ marginTop: 10, marginLeft: 700, width: 300, width: 300 }}>
          <CustomButton id="111" btnClick={btnClickCalled} name="Next" />
        </div>
      </div>
    </div>
  );
};
export default CreateGoogleProfille;
