import React, { useState, useEffect } from "react";
import { uniqueData } from "../utility";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";

import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { visuallyHidden } from "@mui/utils";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import CustomIconButton from "../../src/components/CustomIconButton";
import GooggleProfileData from "../services/GooggleProfileData";
import GooglePlaceActionLinks from "../services/GooglePlaceActionLinks";
var tempModel1 = [];
var _globalPlaceActionLinks = [];

let _globalArray = [];
const ActionType = [
  { id: 0, name: "APPOINTMENT" },
  { id: 1, name: "ONLINE_APPOINTMENT" },
  { id: 2, name: "DINING_RESERVATION" },
  { id: 3, name: "FOOD_ORDERING" },
  { id: 4, name: "FOOD_DELIVERY" },
  { id: 5, name: "FOOD_TAKEOUT" },
  { id: 6, name: "SHOP_ONLINE" },
];
const ErrorDescription = [
  { id: "LINK_ALREADY_EXISTS", name: "Link already exist" },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
var _profilesUsersList = [];

// import MenuItem from '@mui/material/MenuItem';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "S.no",
  },
  {
    id: "providerType",
    numeric: false,
    disablePadding: false,
    label: "Type(Provider)",
  },
  {
    id: "uri",
    numeric: false,
    disablePadding: false,
    label: "Url",
  },
  {
    id: "placeActionType",
    numeric: false,
    disablePadding: false,
    label: "Action(Place)",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

let raedMaskParam =
  "storeCode,name,languageCode,title,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,labels,adWordsLocationExtensions,latlng,openInfo,metadata,profile,relationshipData,moreHours,serviceItems";

const GooglePlaceActionScreen = (route) => {
  var baseselectedRow = [];
  baseselectedRow = route.loc;
  const baseselectedAccountName = route.accountName;
  const backBtn = route.backBtn;

  const navigation = useNavigate();
  const [locationFetchData, setlocationFetchData] = useState([]);
  /// contain slected row object

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [nextPageAccessToken, setNextPageAccessToken] = React.useState("");
  const [stopNextRequest, setStopNextRequest] = React.useState(false);

  const [stopNextProfileRequest, setStopNextProfileRequest] =
    React.useState(false);
  const [profileData, setProfileData] = useState([]);

  const [isAddUser, setIsAddUser] = React.useState(false);
  const [isUpdateUrlAction, setIsUpdateUrlAction] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuLListOpen = Boolean(anchorEl);
  const [groupTextField, setGroupTextField] = useState("");

  const [isActionTypeDialog, setIsActionTypeDialog] = useState(false);
  // const [isGroupDialogOpen, setIsGroupDialogOpen] = React.useState(false);

  const [roleType, setRoleType] = React.useState([]);
  const [selectedCellId, setSelectedCellId] = React.useState(-1);

  const [isRoleUpdated, setIsRoleUpdated] = React.useState(false);
  const [selectedAdminName, setSelectedAdminName] = React.useState("");
  const [isRoleUpdatError, setIsRoleUpdatError] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [totalProfileUsersList, setTotalProfileUsersList] = useState([]);
  const [actionDialogState, setActionDialogState] = useState({
    isEnable: false,
    actionDialogTitle: "",
    text: "",
  });
  const { isEnable, actionDialogTitle, actionDialogText } = actionDialogState;

  const updateActionDialogState = (enable, title, msg) => {
    setActionDialogState({
      ...actionDialogState,
      isEnable: enable,
      actionDialogTitle: title,
      actionDialogText: msg,
    });
  };

  const location = useLocation();

  const getActionType = (type) => {
    switch (type) {
      case "APPOINTMENT":
        return "Appointment";
        break;
      case "ONLINE_APPOINTMENT":
        return "Online Appointment";
        break;
      case "DINING_RESERVATION":
        return "Dining Reservation";
        break;
      case "FOOD_ORDERING":
        return "Order";
        break;
      case "FOOD_DELIVERY":
        return "Delivery";
        break;
      case "FOOD_TAKEOUT":
        return "Takkeuot";
        break;
      case "SHOP_ONLINE":
        return "Shop";
        break;

      default:
        break;
    }
  };

  const handlegTransferSelect = (event, rows) => {
    const {
      target: { value },
    } = event;
    setRoleType(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row1", value);
    // setSelectedAccoutName(value);

    // getGLocation(localStorage.getItem("token"), value);
  };

  const showMenuList = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event);
  };
  const hideMenuList = () => {
    setAnchorEl(null);
  };

  //   console.log("name==", location.state.name);

  const navigateScreen = (params) => {
    navigation("/addgooglelocation", {
      state: {
        name: location.state.name,
        token: "",
      },
    });
  };

  function EnhancedTableToolbar(props) {
    const { numSelected } = props;

    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity
              ),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {/* No item slected */}
          </Typography>
        )}

        {
          numSelected > 0 ? <></> : <></>
          // (
          //   <Tooltip title="Filter list">
          //     <IconButton>
          //       <FilterListIcon />
          //     </IconButton>
          //   </Tooltip>
          // )
        }
      </Toolbar>
    );
  }

  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = locationFetchData.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    // console.log("newSelected", newSelected);
    setSelected(newSelected);
    setSelectedRow(row);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log("nextPageAccessToken", nextPageAccessToken);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const datatModel = (responseData) => {
    console.log("tempModel", responseData);
    let newData = responseData[0];
    const tempModel = [];
    for (let i = 0; i < newData.length; i++) {
      let dic = {};
      let dic2 = {};

      dic.id = i;
      let data = newData[i];
      const name = "name" in data;
      if (name) {
        dic.name = data.name;
      }

      const providerType = "providerType" in data;
      if (providerType) {
        dic.providerType = data.providerType;
      }
      const uri = "uri" in data;
      if (uri) {
        dic.uri = data.uri;
      }

      const placeActionType = "placeActionType" in data;
      if (placeActionType) {
        dic.placeActionType = data.placeActionType;
      }

      tempModel.push(dic);
    }
    setlocationFetchData(tempModel);
  };

  useEffect(() => {
    console.log("_globalPlaceActionLinks.length", _globalPlaceActionLinks);
    if (_globalPlaceActionLinks.length > 0) {
      return;
    }
    const fetchData = async (data) => {
      try {
        console.log("PlaceActionList", data);
        const promises = data.map((item) => GooglePlaceActionLinks(item.name));
        const results = await Promise.all(promises);
        results.forEach((result) => {
          console.log("PlaceActionList Response =", result);
          _globalPlaceActionLinks.push(result);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(baseselectedRow).then((res) => {
      const tmpdata = uniqueData(_globalPlaceActionLinks, "name");
      datatModel(tmpdata);
      console.log("_globalPlaceActionLinks==", tmpdata); // This is attribute values
    });
  }, []); // Empty dependency array to run the effect only once

  const backButtonClicked = () => {
    {
      console.log("2234456");

      // SaveGoogleSheet();
      let array1 = []; // Blank array
      _globalPlaceActionLinks = array1;
      console.log("backButtonClicked==", locationFetchData);

      backBtn();
    }
  };
  const updatePlaceLink = (data) => {
    let updatePlace = "uri";

    console.log("updatePlaceLink", data.name);
    let urlString =
      "https://mybusinessplaceactions.googleapis.com/v1/" +
      data.name +
      "?updateMask=" +
      updatePlace;

    let request = {
      uri: groupTextField,
      placeActionType: roleType[0],
    };

    // let request = {
    //   uri: "https://www.zomato.com/mumbai/blue-tokai-coffee-roasters-versova/order",
    //   placeActionType: "FOOD_ORDERING",
    // };
    try {
      axios
        .patch(urlString, request, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("PlaceActionLink updated Successfully  ", res.data);
          updateActionDialogState(
            true,
            "Success",
            "Order Updated Successfully"
          );
          return res.data;
        })
        .catch((e) => {
          updateActionDialogState(true, "Failed", "Request failed");
          console.log("Error", e.code);
        });
    } catch (error) {
      updateActionDialogState(true, "Failed", "Request failed");
    }
  };

  const createPlaceActionLink = (data) => {
    console.log("updateAttribute", data[0].name);
    let urlString =
      "https://mybusinessplaceactions.googleapis.com/v1/" +
      data[0].name +
      "/placeActionLinks";

    let request = {
      uri: groupTextField,
      placeActionType: roleType[0],
    };
    try {
      axios
        .post(urlString, request, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          // setIsActionTypeDialog(true);
          updateActionDialogState(
            true,
            "Success",
            "Order Created Successfully"
          );
          console.log("order Created Successfully  ", res.data);
          return res.data;
        })
        .catch((e) => {
          // setIsActionTypeDialog(true);
          let msg = "";
          const details = "details" in e.response.data.error;

          if (details) {
            msg = e.response.data.error.details[0].reason;
          } else {
            msg = e.message;
          }
          console.log("e123 =", e.response.data.error.details);

          updateActionDialogState(true, e.code, msg);
          console.log("Error", e.code);
        });
    } catch (error) {
      updateActionDialogState(true, error.code, error.message);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - locationFetchData.length)
      : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(locationFetchData, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [locationFetchData, order, orderBy, page, rowsPerPage]
  );

  const updateUrl = () => {
    setIsUpdateUrlAction(true);
    console.log("selected", selectedRow);
    hideMenuList();
  };

  const handleActionUpdateTypeClose = () => {
    setIsUpdateUrlAction(false);
  };
  const handleUpdateActionTypeBtnClicked = () => {
    // addUserInProfile();
    updatePlaceLink(selectedRow);
    setIsUpdateUrlAction(false);
  };

  // const updateActionBtnClicked = () => {
  //   // console.log("createGroupClicked");
  //   setIsUpdateUrlActiion(true);
  // };

  // const createActionUrlTyppe = () => {
  //   createPlaceActionLink(baseselectedRow);
  // };

  const SaveGoogleSheet = async () => {
    return;
    console.log("selectedRow=", selectedRow);

    let data = {
      "Store Id": "baseselectedAccountName",
      Name: "selectedRow.account",
      "Phone number": "baseselectedRow.name",
    };
    // Cut and Style Location

    axios
      .post(
        "https://sheet.best/api/sheets/9ba14556-8c6d-44be-8abe-d1f1ffab5197",
        locationFetchData
      )
      .then((response) => {
        console.log("saved to google success fully", response);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const handleActiionTypeClose = () => {
    setIsAddUser(false);
  };
  const handleCreateActionTypeBtnClicked = () => {
    // addUserInProfile();
    createPlaceActionLink(baseselectedRow);
    setIsAddUser(false);
  };

  const CreateActionBtnClicked = () => {
    // console.log("createGroupClicked");
    setIsAddUser(true);
  };
  const ActionTypeBtnClicked = (row) => {
    console.log(row);
    window.open(row.uri, "_blank", "noreferrer");
  };

  const handleActionSuccessDialog = () => {
    setIsActionTypeDialog(false);
    updateActionDialogState(false, "", "");
  };

  const addRoleMenu = (
    <div>
      <FormControl style={{ width: 200, marginLeft: 20, marginTop: 20 }}>
        <InputLabel id="demo-multiple-name-label">Choose a type</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          /// multiple
          value={roleType}
          onChange={handlegTransferSelect}
          input={<OutlinedInput label="Name" />}
          MenuProps={MenuProps}
          // style={{ fontSize: 12 }}
        >
          {ActionType.map((item) => (
            <MenuItem
              key={item.id}
              value={item.name} // This will give the account name as a value
              style={getStyles(item.id, roleType, theme)}
              // style={{ fontSize: 12 }}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "white",
        color: "white",
        height: "100vh",
        width: "100%",
        screenLeft: "12344",
        // marginLeft: 240,
        marginLeft: 0,
      }}
    >
      <div style={{ marginTop: 50 }}>
        <CustomIconButton btnClick={backButtonClicked} variant="outlined">
          Back
        </CustomIconButton>
      </div>
      {isEnable ? (
        <Dialog
          open={isEnable}
          onClose={handleActionSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{actionDialogTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {actionDialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleActionSuccessDialog}>Ok</Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {isAddUser ? (
        <Dialog
          open={isAddUser}
          onClose={handleActiionTypeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Set place action type for store code : " +
              baseselectedRow[0].storecode}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            Please enter url and order action type and click on create button.
          </DialogContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              style={{ marginLeft: 20, marginTop: 10 }}
              id="standard-basic"
              label="Enter url here"
              variant="standard"
              onChange={(e) => {
                setGroupTextField(e.target.value);
              }}
            />
          </Box>
          {addRoleMenu}
          <DialogActions>
            <Button onClick={handleActiionTypeClose}>Cancel</Button>
            <Button onClick={handleCreateActionTypeBtnClicked} autoFocus>
              Create
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {isUpdateUrlAction ? (
        <Dialog
          open={isUpdateUrlAction}
          onClose={handleActionUpdateTypeClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Set place action type for store code : " +
              baseselectedRow[0].storecode}
          </DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            Please enter url and order action type and click on Update button.
          </DialogContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "50ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              style={{ marginLeft: 20, marginTop: 10 }}
              id="standard-basic"
              label="Enter url here"
              variant="standard"
              onChange={(e) => {
                setGroupTextField(e.target.value);
              }}
            />
          </Box>
          {addRoleMenu}
          <DialogActions>
            <Button onClick={handleActionUpdateTypeClose}>Cancel</Button>
            <Button onClick={handleUpdateActionTypeBtnClicked} autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      {/* <div
        style={{
          marginLeft: 0,
          boxSizing: "content-box",
          color: "white",
          marginTop: 0,
          fontSize: 30,
          fontFamily: "-moz-initial",
          backgroundColor: "#3386FF",
          height: 60,
          textIndent: 5,
        }}
      >
        <h1>{"location.state.selectdProfileItem.accountName"}</h1>
      </div> */}
      <div style={{ marginBottom: -80 }}>
        <div
          style={{
            width: 400,
            marginLeft: 20,
            color: "Black",
            marginBottom: -100,
            marginTop: 30,
            fontSize: 20,
            fontFamily: "-moz-initial",
          }}
        >
          <h1> Place Actions Type: </h1>
          <span style={{ color: "#3386FF" }}>{baseselectedRow[0].title}</span>
        </div>
        <div
          style={{
            width: 200,
            marginLeft: 750,
            color: "Black",
            marginBottom: -100,
            marginTop: 50,
            fontSize: 30,
            fontFamily: "-moz-initial",
          }}
        >
          <Button onClick={CreateActionBtnClicked} variant="outlined">
            Create order type
          </Button>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          marginTop: 100,
          height: 400,
          width: "90%",
          marginLeft: 10,
        }}
      >
        <Box sx={{ width: "100%", marginLeft: 5 }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar numSelected={selected.length} />
            {selected.length > 0 ? (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {menuItemList()}
              </div>
            ) : (
              <div
                style={{
                  marginLeft: 1050,
                  marginTop: -50,
                }}
              >
                {/* {addBusinessMenuList()} */}
              </div>
            )}
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick} // comment for single use
                  onRequestSort={handleRequestSort}
                  rowCount={locationFetchData.length}
                />
                <TableBody>
                  {visibleRows.map((row, index) => {
                    {
                      console.log("visibleRows", visibleRows);
                    }
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // uncomment iif we want selection feature
                        onClick={(event) =>
                          handleRowClick(event, row.name, row)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        height={80}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        {/* <TableCell style={{ textAlign: "left" }} align="right">
                          {row.name}
                        </TableCell> */}
                        <TableCell align="left">{row.providerType}</TableCell>
                        <TableCell align="left">{row.uri}</TableCell>
                        {/* <TableCell align="left">
                          {row.placeActionType}
                        </TableCell> */}

                        <TableCell
                          align="left"
                          onClick={(event) => ActionTypeBtnClicked(row)}
                        >
                          <Button variant="contained">
                            {getActionType(row.placeActionType)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={locationFetchData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
          <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
          />
        </Box>
      </div>

      <div>
        {isLoading ? (
          <>
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </Dialog>
          </>
        ) : null}
      </div>
    </div>
    // </div>
  );

  function menuItemList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={SaveGoogleSheet} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
            Save to google sheet
          </MenuItem>

          {/* <MenuItem onClick={createActionUrlTyppe} disableRipple>
            Create new action type
          </MenuItem> */}
          <MenuItem onClick={updateUrl} disableRipple>
            {/* <Icon>star</Icon>; */}
            {/* <EditIcon /> */}
            Update order url
          </MenuItem>
        </StyledMenu>
      </div>
    );
  }

  function addBusinessMenuList() {
    return (
      <div>
        <Button
          id="demo-customized-button"
          aria-controls={isMenuLListOpen ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuLListOpen ? "true" : undefined}
          variant="contained"
          disableElevation
          onClick={showMenuList}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Manage users
        </Button>
        {/* <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={isMenuLListOpen}
          onClose={hideMenuList}
        >
          <MenuItem onClick={addSingleBusiiness} disableRipple>
            Add single Business
          </MenuItem>
        </StyledMenu> */}
      </div>
    );
  }
};

export default GooglePlaceActionScreen;
