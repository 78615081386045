import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { GoogleLogin } from "./components/GoogleLogin";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GoogleLocation from "./components/GoogleLocation";
import { LocationData } from "./components/LocationData";
import Dashboard from "./components/DashBoard";
import { GoogleOAuthProvider } from "@react-oauth/google";
import AddGoogleSingleBusiness from "../src/AddGoogleSingleBusiness";
import ManageGoogleUsers from "../src/Screen/ManageGoogleUsers";
import LocationWiseManageUsers from "../src/Screen/LocationWiseManageUsers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function App() {
  return (
    <LocalizationProvider>
      <GoogleOAuthProvider
        clientId="669603285090-ikjqumu3p26ihd1n1mgpfs2mdtciuk0m.apps.googleusercontent.com"
        scope="https://www.googleapis.com/auth/business.manage,https://www.googleapis.com/auth/cloud-platform
      ,https://www.googleapis.com/auth/pubsub"
      >
        {/* <BrowserRouter basename={"/public/gbpv1"}> */}
        <BrowserRouter>
          <Routes>
            {/* <div className="App"> */}

            {/* <Route exact path="/:brand/:accountId" element={<Dashboard />} /> */}
            <Route exact path="/" element={<Dashboard />} />
            {/* uncoommnt letter */}
            {/* <Route exact path="/googlelocation" element={<GoogleLocation />} /> */}
            {/* uncoommnt letter */}
            {/* <Route exact path="/locationdata" element={<LocationData />} /> */}
            <></>
            <Route
              exact
              path="/addgooglelocation"
              element={<AddGoogleSingleBusiness />}
            />
            <Route
              exact
              path="/managegoogleUsers"
              element={<ManageGoogleUsers />}
            />
            <Route
              exact
              path="/manageglocationUsers"
              element={<LocationWiseManageUsers />}
            />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </LocalizationProvider>
  );
}

export default App;
