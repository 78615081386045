import React, { useState } from "react";
import Box from "@mui/material/Box";

import { TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

const CreateProfileForm = (props) => {
  const [isTexterror, setIsTexteError] = useState(false);
  const [textFieldName, setTextFieldName] = useState("");
  const placeHName = props.data.place;
  const textFieldId = props.id;
  console.log("textFieldId", textFieldId);
  let updatText = "";

  if ("textValue" in props) {
    updatText = props.textValue;
    console.log("0", textFieldId + "::" + updatText);
    if (updatText.length > 0 && textFieldId == "222") {
      // setTextFieldName(updatText);
      // console.log("1", updatText);
    }
  }
  // if (textFieldId == "E-444") {
  //   setIsTexteError(true);
  //   console.log("E-444", textFieldName);
  // }

  const onTextClick = (text, id) => {
    // console.log("2", textFieldName);
    console.log("E-444", textFieldId);
    if (text == 0) {
      setIsTexteError(true);
    } else {
      setIsTexteError(false);
    }
    setTextFieldName(text);

    {
      props.funct(text, id);
    }
  };
  return (
    <div style={{ flexDirection: "row", flex: 1, marginLeft: 30 }}>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "35ch", height: "0" },
          // backgroundColor: "green",
          flexDirection: "row",
        }}
        noValidate
        autoComplete="off"
      >
        {!isTexterror ? (
          <TextField
            // value={textFieldName}
            id={textFieldId}
            label={placeHName}
            variant="outlined"
            onChange={(e) => onTextClick(e.target.value, textFieldId)}
          />
        ) : (
          <TextField
            error
            id={textFieldId}
            label={placeHName}
            // defaultValue={placeHName}
            helperText="Error"
            onChange={(e) => onTextClick(e.target.value, textFieldId)}
          />
        )}
      </Box>
    </div>
  );
};

export default CreateProfileForm;
