import React, { useEffect } from "react";
import CreateProfileForm from "../components/CreateProfileForm";
import CustomButton from "../components/CustomButton";

import axios from "axios";
import { useState } from "react";
import CustomList from "../components/CustomList";
import Box from "@mui/material/Box";
import logo from "../asset/busdemo.png";

var rootStyle = {
  height: "100vh",
  width: "100%",
  marginLeft: 0,
  bordrWidth: 10,
};

const ManageOthersGoogleBusiness = (props) => {
  const btnClickCalled = () => {
    {
      props.btnClick();
    }
  };

  return (
    <div style={rootStyle}>
      <div>
        <Box
          component="img"
          sx={{
            height: 580,
            width: 400,
            marginTop: 10,
            marginLeft: 20,
          }}
          src={logo}
        />
      </div>
      <div
        style={{
          width: 700,
          fontSize: 30,
          marginTop: -600,
          marginLeft: 700,
          color: "#3386FF",
        }}
      >
        <h1>Someone else may manage this Business Profile?</h1>
        <div
          style={{
            fontSize: 18,
          }}
        >
          If this email belongs to you, switch accounts, or use Account Recovery
          help guide if you no longer have access to that email address. You can
          also continue to request access from the current manager of this
          business on Google.
        </div>
      </div>

      <div style={{ marginTop: 10, marginLeft: 700, width: 300, width: 300 }}>
        <CustomButton
          id="111"
          btnClick={btnClickCalled}
          name="Request Access"
        />
      </div>
    </div>
  );
};
export default ManageOthersGoogleBusiness;
