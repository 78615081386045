import React, { useEffect } from "react";
import CreateProfileForm from "../components/CreateProfileForm";
import CustomButton from "../components/CustomButton";

import axios from "axios";
import { useState } from "react";
import CustomList from "../components/CustomList";
import Box from "@mui/material/Box";
import logo from "../asset/3.png";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { countryList } from "../../src/utility";
import MultilineTextField from "../../src/components/MultilineTextField";
import FormComponent from "../components/FormComponent";
import WeekdayPicker from "../components/WeekdayPicker";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

var rootStyle = {
  //backgroundColor: "red",

  height: "100vh",
  width: "100%",
  // screenLeft: "12344",
  marginLeft: 0,
  bordrWidth: 10,
};
let mainDataList = [];
const GoogleBusinessAddress = (props) => {
  const [countyISOList, setCountyISOList] = useState([]);
  const [cateFieldTxt, setCateFieldTxt] = useState(false);
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [pinCode, setPinCode] = useState(true);
  const [country, setcountry] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [address, setAddress] = useState([]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [state, setState] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState([]);
  const [extraPhoneNumber, setExtraPhoneNumber] = useState("");
  const [extraPhoneNumber1, setExtraPhoneNumber1] = useState("");
  const [extraPhoneNumber2, setExtraPhoneNumber2] = useState("");
  const [city, setCity] = useState("");
  const [enableExtraPhone2, setEnableExtraPhone2] = useState(false);
  const [divElements, setDivElements] = useState([]);
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [label, setLabel] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [storeCode, setStoreCode] = useState("");
  const [isEnableRegularHour, setIsEnableRegularHour] = useState(false);

  //   let country = "";
  // let city = "";
  // let postalCode = "";
  // let address = "";
  // let state = "";
  // let phoneNumber = "";

  const textClickCalled = (text, id) => {
    switch (id) {
      case "C-444":
        //  city = text;
        setCity(text);
        break;
      case "P-444":
        // postalCode = text;
        setPostalCode(text);
        break;
      case "S-444":
        // state = text;
        setState(text);

        break;
      case "PN-444":
        ///  phoneNumber = text;
        setPhoneNumber(text);
        break;
      case "W-444":
        setWebsite(text);
        break;
      case "P-D-444":
        setDescription(text);
        break;
      case "LA-444":
        console.log("label123", text);
        setLabel(text);
        break;
      case "Lat-444":
        setLatitude(text);
        break;
      case "Long-444":
        setLongitude(text);
        break;
      case "S-C-444":
        setStoreCode(text);
        break;

      // case "APN-444":
      //   let arr = [text];
      //   arr.push();
      //   setExtraPhoneNumber(text);

      //   break;

      default:
        break;
    }
    console.log("88", city + postalCode + country);
  };

  const dynamicTextField = (text, id) => {
    switch (id) {
      case "APN-1":
        setExtraPhoneNumber1(text);
        break;
      case "APN-2":
        setExtraPhoneNumber2(text);

        break;

      default:
        break;
    }
  };

  const onMultiTextClick = (text, id) => {
    console.log("text", text);
    // address = text;
    setAddress1(text);
  };
  const onMultiTextClick2 = (text, id) => {
    console.log("text", text);
    // addrsess = text;
    setAddress2(text);
  };

  useEffect(() => {
    let data = countryList();
    setCountyISOList(data);
  }, []);

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  const handleChange = (event, rows) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    console.log("event row", value);
    // country = value;
    setcountry(value);
    console.log("country row", country);
  };

  const selectedListItem = (data) => {};
  const addExtraPhoneNumber2 = (id) => {
    setEnableExtraPhone2(true);
  };
  const addExtraPhoneCancel2 = (id) => {
    setEnableExtraPhone2(false);
  };

  const regularHours = () => {
    setIsEnableRegularHour(true);
  };

  const btnClickCalled = (callBackData) => {
    console.log("rregu", callBackData);
    const tmpaddress = [];
    tmpaddress.push(address1);
    tmpaddress.push(address2);

    let phoneArray = [];
    console.log("extraPhoneNumber1", extraPhoneNumber1);
    console.log("extraPhoneNumber2", extraPhoneNumber2);
    if (extraPhoneNumber1 != "") {
      phoneArray.push(extraPhoneNumber1);
    }
    if (extraPhoneNumber2 != "") {
      phoneArray.push(extraPhoneNumber2);
    }
    let pArray = [];
    if (phoneArray.length == 0) {
      pArray.push({
        primaryPhone: phoneNumber,
      });
    } else {
      pArray.push({ primaryPhone: phoneNumber, additionalPhones: phoneArray });
    }

    props.btnAddressClick([
      {
        regionCode: country,
        locality: city,
        addressLines: tmpaddress,
        postalCode: postalCode,
        administrativeArea: state.toUpperCase(),
        languageCode: "en",
      },

      {
        phoneNumbers: pArray,
      },
      { websiteUri: website },
      {
        profiles: {
          description: description,
        },
      },
      {
        labels: label,
      },
      {
        latlng: {
          latitude: latitude * 1,
          longitude: longitude * 1,
        },
      },
      {
        StoreCode: storeCode,
      },
      {
        regularHours: {
          periods: callBackData[0],
        },
      },
    ]);
    setIsEnableRegularHour(false);
  };

  // removing div at run time
  const removeDiv = (id) => {
    switch (id) {
      case "APN-1":
        setExtraPhoneNumber1("");
        break;
      case "APN-2":
        setExtraPhoneNumber2("");
        break;

      default:
        break;
    }
    setDivElements((prevDivElements) =>
      prevDivElements.filter((divElement) => divElement.id !== id)
    );
  };

  // addiing div at run time
  const addNewDiv = () => {
    const newDiv = {
      id: `APN-${divElements.length + 1}`,
      place: `Additional phone number ${divElements.length + 1}`,
    };

    setDivElements((prevDivElements) => [...prevDivElements, newDiv]);
  };

  return (
    <div style={rootStyle}>
      {isEnableRegularHour ? (
        <Dialog
          open={true}
          //   onClose={categoryDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Regular time update"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description"></DialogContentText>
            Please enter open and close time.
          </DialogContent>

          <div>
            <WeekdayPicker btnRegularNextClick={btnClickCalled} />
          </div>
        </Dialog>
      ) : null}

      {/* <div>
        <Box
          component="img"
          sx={{
            height: 580,
            width: 400,
            marginTop: 10,
            marginLeft: 20,
          }}
          src={logo}
        />
      </div> */}
      <div
        style={{
          width: 700,
          fontSize: 30,
          marginTop: 0,
          marginLeft: 0,
          color: "#3386FF",
        }}
      >
        Please filled all fields
      </div>
      <div
        style={{
          marginLeft: 20,
          //width: 800,
          // height: 1000,
          marginTop: 0,
          // backgroundColor: "green",

          borderWidth: 0,
          ...{ border: ".5px solid grey" },
        }}
      >
        <div>
          <FormControl style={{ width: 350, marginLeft: 38, marginTop: 50 }}>
            <InputLabel id="demo-multiple-name-label">Country</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              /// multiple
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              {countyISOList.map((item) => (
                <MenuItem
                  key={item.iso2}
                  value={item.iso2} // This will give the account name as a value
                  style={getStyles(item.iso2, personName, theme)}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {/* Need to improve thiis by buttton click adding */}
        <div>
          <MultilineTextField
            onTextClick={onMultiTextClick}
            title="Address line 1"
          />
        </div>
        <div>
          <MultilineTextField
            onTextClick={onMultiTextClick2}
            title="Address line 2"
          />
        </div>
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter store code" }}
          id="S-C-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "City" }}
          id="C-444"
        />
        {pinCode ? (
          <CreateProfileForm
            funct={textClickCalled}
            data={{ place: "Pincode" }}
            id="P-444"
          />
        ) : (
          <CreateProfileForm
            funct={textClickCalled}
            data={{ place: "Pincode" }}
            id="E-444"
          />
        )}
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "State" }}
          id="S-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter Website" }}
          id="W-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter description" }}
          id="P-D-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter Labels with comma separated" }}
          id="LA-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter latitude" }}
          id="Lat-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Enter longitude" }}
          id="Long-444"
        />
        <CreateProfileForm
          funct={textClickCalled}
          data={{ place: "Phone number" }}
          id="PN-444"
        />

        <div>
          {divElements.map((divElement) => (
            <div key={divElement.id}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CreateProfileForm
                  funct={dynamicTextField}
                  data={{ place: divElement.place }}
                  id={divElement.id}
                />
                <CustomButton
                  id={`Button-${divElement.id}`}
                  btnClick={() => removeDiv(divElement.id)}
                  name="Cancel"
                />
              </div>
            </div>
          ))}
          {divElements.length <= 1 ? (
            <div style={{ marginTop: 5, marginLeft: 36 }}>
              <CustomButton
                id="addButton"
                btnClick={addNewDiv}
                name="Add additional phone number"
              />
            </div>
          ) : null}
        </div>

        <div
          style={{
            marginTop: "10px",
            marginLeft: "700px",
            width: "500px",
          }}
        >
          <CustomButton id="111" btnClick={regularHours} name="Next" />
        </div>
      </div>
    </div>
  );
};
export default GoogleBusinessAddress;
