import { types } from "../Action/types";

const initialState = {
  payload: [],
  type: "",
};

const googleBusinessReducer = (state = initialState, action) => {
  console.log("payload=", action.payload, action.type);
  switch (action.type) {
    case types.FETCH_GOOGLE_ACCOUNT:
      return { ...state, payload: action.payload };

    case types.FETCH_GOOGLE_ACCOUNT_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        type: "FETCH_GOOGLE_ACCOUNT_SUCCESS",
      };
    case types.FETCH_GOOGLE_ACCOUNT_FAILED:
      return { ...state, payload: [] };
    // case types.RESET_STATE_GOOGLE_ACCOUNT:
    //   return { state: [] };
    default:
      return state;
  }
};

export default googleBusinessReducer;
