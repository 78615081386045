import * as React from "react";
import Box from "@mui/material/Box";
// Dialogg start
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Dialog end

import { TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { useState, useEffect } from "react";
import getGoogleBusiness from "..//src/services/GoogleBusiness";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import CreateGoogleProfille from "../src/Screen/CreateGoogleProfille";
import CustomList from "../src/components/CustomList";
import axios from "axios";
import CustomIconButton from "../src/components/CustomIconButton";
import CustomButton from "../src/components/CustomButton";
import GoogleBusinessAddress from "../src/Screen/GoogleBusinessAddress";
import ManageOthersGoogleBusiness from "./Screen/ManageOthersGoogleBusiness";
import { vaidateData } from "../src/utility";

import CircularProgress from "@mui/material/CircularProgress";

var rootStyle = {
  backgroundColor: "white",

  height: "100vh",
  width: "100%",
  screenLeft: "12344",
  marginLeft: 0,
  bordrWidth: 10,
};

const AddGoogleSingleBusiness = (route) => {
  const [isFullFormVisible, setIsFullFormVisible] = useState(false);
  const [isSendBtnEnable, setIsSendBtnEnable] = useState(false);
  const [searchCompanyName, setSearchCompany] = useState("");
  const [isAddingNewBusiness, setIsAddingNewBusiness] = useState(false);
  const [createSteps, setCreateSteps] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [businessdata, setBusinessData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyAddressData, setCompanyAddressData] = useState([]);
  const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
  const [isBusinessRequestUrl, setIsBusinessRequestUrl] = React.useState(false);
  const [requestUrlName, setRequestUrlName] = React.useState("");
  const [coordinate, setCoordinate] = React.useState([]);
  const [isProfileCreated, setisProfileCreated] = useState(false);
  const [isProfileCreateFail, setisProfileCreateFail] = useState(false);
  const location = useLocation();
  console.log("name12", location.state.name);
  const brandId = location.state.name;
  const navigate = useNavigate();

  const navigateToRoot = () => {
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert("The name you entered was");
  };

  useEffect(() => {
    //  testCreateLocationRequst();
  }, []);

  const addNewBusinessBtnClicked = () => {
    // setIsAddingNewBusiness(true);
    /// setCreateSteps(1);
    testCreateLocationRequst(); // UNCOMMENT FOR DIRECT ADD STOORER VIA JSON
  };

  const createLocationSuccessDialogClose = () => {
    setDialogIsOpen(false);
  };

  const getCordinate = (address1) => {
    const apiKey = "AIzaSyAX45iK-vY21o8i1unOgHY6ywNk49EoS7o"; // Replace with your actual API key

    const geocodeAddress = async (address) => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${apiKey}`
        );
        const data = await response.json();

        if (data.status === "OK" && data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;

          let cordi = { lati: lat, longi: lng };

          //  sendCreateLocationRequst(cordi);
          console.log("Latitude:", lat);
          console.log("Longitude:", lng);
        } else {
          console.log("Geocoding failed. Status:", data);
        }
      } catch (error) {
        console.error("Error geocoding address:", error);
      }
    };

    // Example usage
    geocodeAddress(address1);
  };

  const sendCreateLocationRequst = (coordinate) => {
    let cdata = companyData;
    let cAdd = companyAddressData;
    let request = {
      title: companyData.title,
      languageCode: "en",
      websiteUri: "www.locoqiz.com",
      categories: {
        primaryCategory: { name: cdata.categories.primaryCategory.name },
      },
      storefrontAddress: cAdd[0],
      phoneNumbers: cAdd[1],
      latlng: { latitude: coordinate.lati, longitude: coordinate.longi },
    };

    let re = {
      languageCode: "en",
      storeCode: "4736",
      title: "Max Lab",
      phoneNumbers: {
        primaryPhone: "9646425103",
      },
      categories: {
        primaryCategory: {
          name: "categories/gcid:diagnostic_center",
        },
        additionalCategories: [
          {
            name: "categories/gcid:pathologist",
          },
          {
            name: "categories/gcid:medical_lab",
          },
          {
            name: "categories/gcid:blood_testing_service",
          },
        ],
      },
      storefrontAddress: {
        regionCode: "IN",
        postalCode: "134112",
        administrativeArea: "Punjab",
        locality: "Panchkula",
        languageCode: "en",
        addressLines: ["Booth No 227, Sector 21 Road, Sector 21, Budanpur"],
      },
      websiteUri: "https://www.maxlab.co.in/",
      regularHours: {
        periods: [
          {
            openDay: "MONDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "MONDAY",
            closeTime: {
              hours: 20,
            },
          },
          {
            openDay: "TUESDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "TUESDAY",
            closeTime: {
              hours: 20,
            },
          },
          {
            openDay: "WEDNESDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "WEDNESDAY",
            closeTime: {
              hours: 20,
            },
          },
          {
            openDay: "THURSDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "THURSDAY",
            closeTime: {
              hours: 20,
            },
          },
          {
            openDay: "FRIDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "FRIDAY",
            closeTime: {
              hours: 20,
            },
          },
          {
            openDay: "SATURDAY",
            openTime: {
              hours: 7,
            },
            closeDay: "SATURDAY",
            closeTime: {
              hours: 20,
            },
          },
        ],
      },
      labels: [
        "Diagnostic Centre, Pathology Lab, Blood Test, Healthcare, online lab test, Full Body Health Check, Covid IgG Antibody Detection, Allergy Screen Profile, Real Time PCR, Thyroid Test",
      ],
      latlng: {
        latitude: 30.675616,
        longitude: 76.863778,
      },
      profile: {
        description:
          "Max Lab is a division of Max Healthcare. We are equipped with fully automated state-of- art technology and infrastructure to achieve the highest level of excellence and quality results, Get lab test done from the comfort of your home with NABL certified Max Lab",
      },
    };

    let address = cAdd[0].addressLines;

    console.log("address", address);

    console.log("request", request);
    let url =
      "https://mybusinessbusinessinformation.googleapis.com/v1/" +
      "accounts/107583687904434700988" +
      "/locations";

    console.log("url=", url, "::", request);

    try {
      axios
        .post(url, re, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.status == 200) {
            setCreateSteps(0);
            setDialogIsOpen(true);
          }
          console.log("Location added in to Account  ", res.data);
          return res.data;
        })
        .catch((e) => console.log("Error", e.code));
    } catch (error) {}
  };
  const testCreateLocationRequst = () => {
    // const re = [
    //   {
    //     languageCode: "en",
    //     storeCode: "BS10066",
    //     title: "MFine Diagnostics",
    //     phoneNumbers: {
    //       primaryPhone: "7249210752",
    //       // additionalPhones: ["1303505980"],
    //     },

    //     categories: {
    //       primaryCategory: {
    //         name: "categories/gcid:diagnostic_center",
    //       },
    //       additionalCategories: [
    //         {
    //           name: "categories/gcid:medical_lab",
    //         },
    //         {
    //           name: "categories/gcid:diagnostic_center",
    //         },
    //         {
    //           name: "categories/gcid:blood_testing_service",
    //         },
    //         // {
    //         //   name: "categories/gcid:metal_fabricator",
    //         // },
    //         // {
    //         //   name: "categories/gcid:laminating_equipment_supplier",
    //         // },
    //       ],
    //     },
    //     storefrontAddress: {
    //       regionCode: "IN",
    //       postalCode: "411027",
    //       administrativeArea: "Maharashtra".toUpperCase(),
    //       locality: "Vitthal Nagar",
    //       addressLines: [
    //         "Shop No 162, 1st Floor, Vision Flora, Kunal Icon Road, Pimpri-Chinchwad",
    //         "Pimpri-Chinchwad",
    //       ],
    //     },
    //     //  websiteUri:
    //     //   "https://stores.vishalmegamart.com/shopping-mart-bhavara-madhubani-35110/home?utm_source=GMB",
    //     regularHours: {
    //       periods: [
    //         {
    //           openDay: "SUNDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 0,
    //           },
    //           closeDay: "SUNDAY",
    //           closeTime: {
    //             hours: 14,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "MONDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "MONDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "TUESDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "TUESDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "WEDNESDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "WEDNESDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "THURSDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "THURSDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "FRIDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "FRIDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //         {
    //           openDay: "SATURDAY",
    //           openTime: {
    //             hours: 7,
    //             minutes: 30,
    //           },
    //           closeDay: "SATURDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 0,
    //           },
    //         },
    //       ],
    //     },
    //     labels: [
    //       "Diagnostic Centre",
    //       "Pathology Lab",
    //       "Blood Test",
    //       "Healthcare",
    //       "online lab test",
    //     ],
    //     latlng: {
    //       latitude: 18.692761,
    //       longitude: 73.799849,
    //     },

    //     profile: {
    //       description:
    //         "MFine redefines the delivery system of primary healthcare in India. We help you find leading doctors across India and connect with them online via chat, audio and video calls. We also offer at-home health checks and doorstep medicine delivery. For all your healthcare needs, you can now say, I'm fine, thanks to MFine, for Online doctor consultations | At-home lab tests | X-Ray & Scans | Medicine delivery.",
    //     },
    //   },
    // ];

    // const re = [
    //   {
    //     location: {
    //       languageCode: "en",
    //       storeCode: "K93",
    //       title: "Jockey Exclusive Store",
    //       phoneNumbers: {
    //         primaryPhone: "4842981884s",
    //         additionalPhones: ["9778173257"],
    //       },

    //       categories: {
    //         primaryCategory: {
    //           name: "categories/gcid:clothing_store",
    //         },
    //         additionalCategories: [
    //           {
    //             name: "categories/gcid:underwear_store",
    //           },
    //           {
    //             name: "categories/gcid:baby_clothing_store",
    //           },
    //           {
    //             name: "categories/gcid:childrens_clothing_store",
    //           },
    //           {
    //             name: "categories/gcid:womens_clothing_store",
    //           },
    //           {
    //             name: "categories/gcid:mens_clothing_store",
    //           },
    //           {
    //             name: "categories/gcid:lingerie_store",
    //           },
    //           {
    //             name: "categories/gcid:sportswear_store",
    //           },
    //         ],
    //       },
    //       storefrontAddress: {
    //         regionCode: "IN",
    //         postalCode: "682301",

    //         administrativeArea: "Kerala".toUpperCase(),
    //         locality: "Ernakulam",
    //         addressLines: [
    //           "11/202, Carnival Hi-Street Mall, Irumpanam, Trippunithura",
    //           "Near Heera Lifestyle appartment",
    //         ],
    //       },
    //       websiteUri:
    //         "https://stores.jockey.in/jockey-exclusive-store-trippunithura-ernakulam-35108/home?utm_source=GMB",
    //       regularHours: {
    //         periods: [
    //           {
    //             openDay: "SUNDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 20,
    //             },
    //             closeDay: "SUNDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "MONDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "MONDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "TUESDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "TUESDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "WEDNESDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "WEDNESDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "THURSDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "THURSDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "FRIDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "FRIDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //           {
    //             openDay: "SATURDAY",
    //             openTime: {
    //               hours: 10,
    //               minutes: 30,
    //             },
    //             closeDay: "SATURDAY",
    //             closeTime: {
    //               hours: 21,
    //               minutes: 30,
    //             },
    //           },
    //         ],
    //       },
    //       labels: [
    //         "Innerwear",
    //         "lingerie",
    //         "fashion",
    //         "underwear",
    //         "shapewear",
    //         "bra",
    //         "Sleepwear",
    //       ],
    //       latlng: {
    //         latitude: 9.956938,
    //         longitude: 76.355075,
    //       },

    //       profile: {
    //         description:
    //           "Think Comfort, Think Jockey. Jockey Is The Leading Manufacturer And Marketer Of Comfort Apparel Sold In More Than 140 Countries As Well As One Of The World's Most Loved Brands. After Leading The Innerwear Category For Both Men And Women, Jockey Has Moved To Widening Its Offering In The Outerwear Category With T-shirts, Jackets, Trackpants, Loungewear, Socks, Towels, Tank Tops, Capris, Shorts, Thermals, And The Entire Kidswear Range. So, If You're Looking To Stay Comfortable, No Matter What You're Doing, Jockey Is The Right Brand For You, Our Jockey Exclusive Store Near Trippunithura, Ernakulam.",
    //       },
    //     },
    //     brandId: "accounts/114434690896611327662",
    //   },
    // ];

    // const re = [
    //   {
    //     languageCode: "en",
    //     storeCode: "86",
    //     title: "Cut and Style",
    //     phoneNumbers: {
    //       primaryPhone: "0130 4034249",
    //       // additionalPhones: ["12"],
    //     },

    //     categories: {
    //       primaryCategory: {
    //         name: "categories/gcid:unisex_hairdresser",
    //       },
    //       additionalCategories: [
    //         {
    //           name: "categories/gcid:nail_salon",
    //         },
    //         {
    //           name: "categories/gcid:beauty_salon",
    //         },
    //       ],
    //     },
    //     storefrontAddress: {
    //       regionCode: "IN",
    //       postalCode: "131001",
    //       administrativeArea: "HARYANA",
    //       locality: "Sonipat",
    //       addressLines: [
    //         "SCO 150 Main Market , Sector 15",
    //         "Nearby DAV Multipurpose school",
    //       ],
    //     },
    //     websiteUri:
    //       "https://salons.cutandstyle.in/sector-15-sonipat-36715/home?utm_source=GMB",
    //     regularHours: {
    //       periods: [
    //         {
    //           openDay: "SUNDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "SUNDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "MONDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "MONDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "TUESDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "TUESDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "WEDNESDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "WEDNESDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "THURSDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "THURSDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "FRIDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "FRIDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //         {
    //           openDay: "SATURDAY",
    //           openTime: {
    //             hours: 9,
    //             minutes: 30,
    //           },
    //           closeDay: "SATURDAY",
    //           closeTime: {
    //             hours: 20,
    //             minutes: 30,
    //           },
    //         },
    //       ],
    //     },
    //     labels: [
    //       "Beauty salon",
    //       "makeup",
    //       "bridal makeup",
    //       "hair treatment",
    //       "nail bar",
    //       "men's hair",
    //       "women's hair",

    //       "hair color",
    //       "hair straightening",
    //       "professional hair services",
    //     ],
    //     latlng: {
    //       latitude: 29.0000541,
    //       longitude: 77.04006133,
    //     },

    //     profile: {
    //       description:
    //         "Beauty salon, makeup, bridal makeup, hair treatment, nail bar, men's hair, women's hair, beauty services, facial, hair color, hair straightening, professional hair services",
    //     },
    //   },
    // ];
    const re = [
      {
        location: {
          languageCode: "en",
          storeCode: "I59",
          title: "Jockey Exclusive Store",
          phoneNumbers: {
            primaryPhone: "0121 5834 083",
            // additionalPhones: ["7033306621"],
          },

          categories: {
            primaryCategory: {
              name: "categories/gcid:clothing_store",
            },
            additionalCategories: [
              {
                name: "categories/gcid:underwear_store",
              },
              {
                name: "categories/gcid:baby_clothing_store",
              },
              {
                name: "categories/gcid:childrens_clothing_store",
              },
              {
                name: "categories/gcid:womens_clothing_store",
              },
              {
                name: "categories/gcid:mens_clothing_store",
              },
              {
                name: "categories/gcid:lingerie_store",
              },
              {
                name: "categories/gcid:sportswear_store",
              },
            ],
          },
          storefrontAddress: {
            regionCode: "IN",
            postalCode: "800025",

            administrativeArea: "Bihar".toUpperCase(),
            locality: "Patna",
            addressLines: [
              "Shop No 2,3,4,  Ground Floor and First Floor, Ashiyana Digha Road",
              "Jamuna Arcade, Kautilya Nagar, Opposite Canara Bank",
            ],
          },
          websiteUri:
            "https://stores.jockey.in/jockey-exclusive-store-kautilya-nagar-patna-36478/home?utm_source=GMB",
          regularHours: {
            periods: [
              {
                openDay: "SUNDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "SUNDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "MONDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "MONDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "TUESDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "TUESDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "WEDNESDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "WEDNESDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "THURSDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "THURSDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "FRIDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "FRIDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
              {
                openDay: "SATURDAY",
                openTime: {
                  hours: 10,
                  minutes: 0,
                },
                closeDay: "SATURDAY",
                closeTime: {
                  hours: 21,
                  minutes: 0,
                },
              },
            ],
          },
          labels: [
            "Innerwear",
            "lingerie",
            "fashion",
            "underwear",
            "shapewear",
            "Sleepwear",
            "bra",
          ],
          latlng: {
            latitude: 25.62034,
            longitude: 85.08273,
          },

          profile: {
            description:
              "Think Comfort, Think Jockey. Jockey Is The Leading Manufacturer And Marketer Of Comfort Apparel Sold In More Than 140 Countries As Well As One Of The World's Most Loved Brands. After Leading The Innerwear Category For Both Men And Women, Jockey Has Moved To Widening Its Offering In The Outerwear Category With T-shirts, Jackets, Trackpants, Loungewear, Socks, Towels, Tank Tops, Capris, Shorts, Thermals, And The Entire Kidswear Range. So, If You're Looking To Stay Comfortable, No Matter What You're Doing, Jockey Is The Right Brand For You, Our Jockey Exclusive Store Near Kautilya Nagar, Patna.",
          },
        },
        brandId: "accounts/114434690896611327662",
      },
    ];

    for (let i = 0; i < 1; i++) {
      console.log("121", re[0].location, localStorage.getItem("token"));

      let url =
        "https://mybusinessbusinessinformation.googleapis.com/v1/" +
        "accounts/114434690896611327662" +
        "/locations";

      try {
        axios
          .post(url, re[0].location, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            if (res.status == 200) {
              console.log("Location added in to Account  ", res.data);
              return res.data;
            }
            console.log("Location added in to Account  ", res.data);
            return res.data;
          })
          .catch((e) => console.log("Error", e.code));
      } catch (error) {}
    }
  };
  const createGoogleProfiileRequest = () => {
    let cAdd = companyAddressData;
    let address = cAdd[0].addressLines;
    getCordinate(address);
  };

  const setCompanyName = async (e) => {
    // TODO- Inttrduce Redux and Saga middle ware here to managge state and moved tthis networkk call into saga

    if (e.length <= 0) {
      setBusinessData([]);
      setIsBusinessRequestUrl(false);
    } else if (e.length > 3) {
      let location = {
        query: e,
      };

      console.log("getGoogleBusiness called", location);
      let url =
        "https://mybusinessbusinessinformation.googleapis.com/v1/googleLocations:search";

      console.log("url=", url, "::", location);

      try {
        axios
          .post(url, location, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("Category found", res.data);
            // if ("googleLocations" in res.data) {
            setBusinessData(res.data.googleLocations);
            // }

            return res.data;
          })
          .catch((e) => console.log("Error", e));
      } catch (error) {}
    }
    console.log("ontextchange called", e);
  };

  const requestButtonClick = () => {
    console.log("requestButtonClick");
    window.open(requestUrlName, "_blank", "noreferrer");
  };

  const nextBtnClickCallback = (data) => {
    const result =
      vaidateData(data.categories.primaryCategory.name) &&
      vaidateData(data.title);
    if (!result) {
      return;
    }

    console.log("nextBtnClickCallback1=", data);
    setCompanyData(data);
    setCreateSteps(2);
  };

  // const nextBtnGAddCallback = async (data) => {
  //   console.log("nextBtnGAddCallback", data);

  //   // let cdata = companyData;
  //   // let cAdd = companyAddressData;

  //   setCompanyAddressData(data);
  //   // createGoogleProfiileRequest(); // Uncomment later
  //   let cdata = companyData;
  //   let cAdd = companyAddressData;
  //   console.log("cdata", cdata);
  //   const data1 = companyData.categories;
  //   const filteredNames = {
  //     primaryCategory: data1.primaryCategory.name,
  //     additionalCategory: data1.additionalCategory.map(
  //       (category) => category.name
  //     ),
  //   };
  //   console.log("companyAddressData", filteredNames);
  //   let request = {
  //     title: companyData.title,
  //     categories: filteredNames,
  //     languageCode: "en",
  //     storefrontAddress: cAdd[0],
  //     phoneNumbers: cAdd[1],

  //     websiteUri: cAdd[2].websiteUri,
  //     profiles: cAdd[3].profiles,
  //     labels: cAdd[4].labels,

  //     latlng: cAdd[5].latlng,
  //     storeCode: cAdd[6].StoreCode,

  //     regularHours: cAdd[7].regularHours,
  //   };

  //   const fional_request = {
  //     location: request,
  //     brandId: brandId,
  //   };
  //   console.log("Final request", fional_request);
  //   setIsLoading(true);
  //   await postProfilleRequest(fional_request);
  //   console.log("Final request", fional_request);
  // };

  const nextBtnGAddCallback = (data) => {
    console.log("nextBtnGAddCallback", data);

    setCompanyAddressData(data);
    let cdata = companyData;
    let cAdd = data;
    console.log("cdata", cdata.categories);
    console.log("cdata", cdata.categories.addittionalCategory);

    const filteredNames = {
      primaryCategory: { name: cdata.categories.primaryCategory.name },
      additionalCategories: cdata.categories.addittionalCategory.map(
        (category) => ({ name: category.name })
      ),
    };
    console.log("companyAddressData", filteredNames);
    const number = cAdd[1].phoneNumbers;
    const labels = cAdd[4].labels;
    const nwll = labels.split(", ");
    console.log("labels:", nwll);
    const newl = labels.split(",");
    console.log("array:", newl);
    const tmpprofile = cAdd[3].profiles;

    let request = {
      title: cdata.title,
      categories: filteredNames,
      languageCode: "en",
      storefrontAddress: cAdd[0],
      phoneNumbers: number[0],
      websiteUri: cAdd[2].websiteUri,
      profile: tmpprofile,
      labels: newl,
      latlng: cAdd[5].latlng,
      storeCode: cAdd[6].StoreCode,
      regularHours: cAdd[7].regularHours,
    };
    let brandID = brandId;
    switch (process.env.REACT_APP_RUN_ENVIORNMENT) {
      case "GBM":
        brandID = brandId;

        break;
      case "DCC":
        brandID = localStorage.getItem("DCC-BRANDID");
        break;

      default:
        break;
    }

    const final_request = {
      location: request,
      brand_id: brandID,
    };
    console.log("Final request", final_request);

    setIsLoading(true);

    postProfilleRequest(final_request);
    console.log("Final request", final_request);
  };

  async function postProfilleRequest(data) {
    console.log("postProfilleRequest", data);
    const request = data;
    console.log("23455", request);
    try {
      const response = await axios.post(
        process.env.REACT_APP_LOCOWIZ_URL + "/api/add-location",
        request,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // if (response.status == 200) {
      console.log("Profile added successfully", response.data);
      setIsLoading(false);
      setisProfileCreated(true);
      setisProfileCreateFail(false);
      return response.data;
    } catch (error) {
      console.log("Profile add request failed", error.response.data.error);
      setIsLoading(false);
      setisProfileCreated(false);
      setisProfileCreateFail(true);
      throw error;
    }
  }

  const businesSearchSelectedItem = (data) => {
    console.log("businesSearchSelectedItem called", data);

    if ("requestAdminRightsUri" in data) {
      //const url = data.requestAdminRightsUri;
      setRequestUrlName(data.requestAdminRightsUri);
      setIsBusinessRequestUrl(true);
      setCreateSteps(-1);
    }

    // getGoogleBusiness(location.state.name, request);
  };

  const btnBackCalled = (text, id) => {
    console.log(333);
    setIsAddingNewBusiness(false);
    let step = 0;
    if (createSteps == -1) {
      setCompanyName("");
      step = 1;
    } else {
      setCompanyName("");
      step = createSteps;
    }
    step = step - 1;
    setCreateSteps(step);
    console.log("step", step);
    switch (id) {
      case 333:
        console.log(333);

        break;
      default:
        break;
    }
  };
  const textFieldTextChange = () => {};
  const handleProfileSuccessDialog = () => {
    setisProfileCreated(false);
    //  navigateToRoot();
  };
  const handleProfileFailDialog = () => {
    setisProfileCreateFail(false);
    //  navigateToRoot();
  };
  return (
    <div style={rootStyle}>
      <div>
        {isProfileCreated ? (
          <Dialog
            open={isProfileCreated}
            onClose={handleProfileSuccessDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Profile Creation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Profile created successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleProfileSuccessDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      <div>
        {/* {console.log("isGroupCreated", isGroupCreated)} */}
        {isProfileCreateFail ? (
          <Dialog
            open={isProfileCreateFail}
            onClose={handleProfileFailDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Group Creation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Please try again .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleProfileFailDialog}>Ok</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </div>
      <div>
        {isLoading ? (
          <>
            <Dialog
              open={true}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            </Dialog>
          </>
        ) : null}
      </div>

      {/* //223244567689 */}
      <div
        style={{
          backgroundColor: "#3386FF",
          //   textAlign: "center",
          fontSize: 30,
          borderWidth: "0",
          borderBlockColor: "black",

          height: "50px",
          boxShadow: "1px 2px 9px #130d01",
          // margin: "1em",
          // padding: "1em",
          color: "white",
        }}
      >
        Manage your Business
      </div>
      {createSteps != 0 ? (
        <div style={{ marginTop: 10, marginLeft: 30, width: 300, width: 300 }}>
          <CustomIconButton
            id="333"
            btnClick={btnBackCalled}
            name=""
            type="imageBtn"
          />
        </div>
      ) : null}
      {createSteps == -1 && isBusinessRequestUrl ? (
        <ManageOthersGoogleBusiness btnClick={requestButtonClick} />
      ) : (
        {
          ...(createSteps == 0
            ? findBusinesssDiv()
            : {
                ...(createSteps == 1 ? (
                  <CreateGoogleProfille btnClick={nextBtnClickCallback} />
                ) : (
                  <GoogleBusinessAddress
                    btnAddressClick={nextBtnGAddCallback}
                  />
                )),
              }),
        }
      )}
    </div>
  );

  function findBusinesssDiv() {
    return (
      <div style={{ flexDirection: "row", flex: 1, marginLeft: 30 }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "25ch", height: "0" },
            // backgroundColor: "green",
            flexDirection: "row",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-basic"
            label="Type your business name"
            variant="outlined"
            onChange={(e) => setCompanyName(e.target.value)}
          />
          {console.log("businessdata=", businessdata)}
          {businessdata.length > 0 ? (
            <CustomList
              data={businessdata}
              onclick={businesSearchSelectedItem}
            />
          ) : null}

          {isFullFormVisible ? (
            <>
              <TextField id="filled-basic" label="Filled" variant="filled" />
              <TextField
                id="standard-basic"
                label="Standard"
                variant="standard"
              />
            </>
          ) : null}
        </Box>
        <div>
          <label style={{ marginLeft: 20, fontSize: 14, color: "gray" }}>
            Can't find your business?
          </label>
        </div>
        <div>
          <Button
            style={{ marginLeft: 10, marginTop: 20, fontSize: 15 }}
            onClick={addNewBusinessBtnClicked}
          >
            Add your business to Google
          </Button>
        </div>

        <div>
          <Dialog
            open={dialogIsOpen}
            onClose={createLocationSuccessDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Add location"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your location has been added successfully.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={createLocationSuccessDialogClose}>Ok</Button>
              {/* <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
};

export default AddGoogleSingleBusiness;
