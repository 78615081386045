import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const CustomIconButton = (props) => {
  const btnName = props.name;
  const btnClick = props.btnClick;
  //   const btnClicked = (event) => {
  //     {
  //       props.btnClick(event);
  //     }
  //   };
  return (
    <div>
      <IconButton
        onClick={btnClick}
        color="primary"
        aria-label="add to shopping cart"
      >
        <ArrowBackIcon />
      </IconButton>
    </div>
  );
};

export default CustomIconButton;
