import { put, call, takeEvery } from "redux-saga/effects";
// import types from "../types";
import { types } from "../Action/types";
import { uniqueData } from "../../utility";
import {
  getGoogleBusinesss,
  getGoogleProfiles,
  getDCCBusinesss,
} from "../../services/GoogleBusinessService";

function* getBusinessAccount(action) {
  console.log("action11", action);
  try {
    /* for GBM
    const business = yield call(() => getGoogleBusinesss(action.payload));
    // const business = yield call(() => getDCCBusinesss(action.payload));

    console.log("business", business);
    let arr = uniqueData(business, "name");

    yield put({ type: types.FETCH_GOOGLE_ACCOUNT_SUCCESS, payload: arr });
    */

    switch (process.env.REACT_APP_RUN_ENVIORNMENT) {
      case "GBM":
        const business = yield call(() => getGoogleBusinesss(action.payload));

        console.log("business", business);
        let arr = uniqueData(business, "name");

        yield put({ type: types.FETCH_GOOGLE_ACCOUNT_SUCCESS, payload: arr });

        break;
      case "DCC":
        const dccbusiness = yield call(() => getDCCBusinesss(action.payload));
        yield put({
          type: types.FETCH_GOOGLE_ACCOUNT_SUCCESS,
          payload: dccbusiness,
        });

      default:
        break;
    }

    // const business = yield call(() => getDCCBusinesss(action.payload));
    // yield put({ type: types.FETCH_GOOGLE_ACCOUNT_SUCCESS, payload: business });
  } catch (err) {
    console.log("err=", err);
    yield put({
      type: types.FETCH_GOOGLE_ACCOUNT_FAILED,
      payload: err,
    });
    console.log(err);
  }
}

// function* getGoogleAccountProfiles() {
//   try {
//     const busineses = yield call(getGoogleProfiles);
//     yield put({ type: types.FETCH_GOOGLE_PROFILE_SUCCESS, payload: busineses });
//   } catch (err) {
//     yield put({
//       type: types.FETCH_GOOGLE_PROFILE_FAILURE,
//       payload: err,
//     });
//     console.log(err);
//   }
// }

export default function* GoogleBusinessSaga() {
  yield takeEvery(types.FETCH_GOOGLE_ACCOUNT, getBusinessAccount);
  //yield takeEvery(types.FETCH_GOOGLE_PROFILE, getGoogleAccountProfiles);
}
