import React, { useState, useEffect } from "react";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { Button } from "react-bootstrap";
import urlPathContext from "./Conntext/UrlPathContext";
import { useContext } from "react";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import { useDispatch, useSelector } from "react-redux";

import { fetchGAccoount } from "./Redux/Action/GoogleBusinessActions";

function LoginGoogle() {
  const dispatch = useDispatch();

  const [profile, setProfile] = useState([]);
  const [refreshToken, setRefreshToken] = useState("");
  const fetchGAccountData = useSelector((state) => state.googleBusinessReducer);

  const [loading, setLoading] = useState(false);

  const value = useContext(urlPathContext);
  console.log("LoginPageUrl", value);

  useEffect(() => {
    if (fetchGAccountData.type === "FETCH_GOOGLE_ACCOUNT_SUCCESS") {
      setLoading(false);
    }
  });

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("codeResponse", codeResponse);
      const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
      console.log("clientId =", clientId);
      const clientSecret = process.env.REACT_APP_GOOGLE_CLIENT_SECRET;
      const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
      exchangeCodeForToken(
        codeResponse.code,
        clientId,
        clientSecret,
        redirectUri
      );
    },
    flow: "auth-code",
  });

  <Button onClick={() => login()}>Sign in with Google 🚀 </Button>;

  const exchangeCodeForToken = async (
    authorizationCode,
    clientId,
    clientSecret,
    redirectUri
  ) => {
    console.log("redirectUri", redirectUri);
    const tokenEndpoint = "https://oauth2.googleapis.com/token";

    try {
      const response = await axios.post(tokenEndpoint, {
        code: authorizationCode,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
      });

      const accessToken = response.data.access_token;
      localStorage.setItem("token", response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      console.log("Access token111:", response.data);
      //dispatch(fetchGAccoount(""));
      if (fetchGAccountData.payload.length <= 0) {
        console.log("fetchGAccountData start");
        setLoading(true);
        dispatch(fetchGAccoount(""));
      } else {
        console.log("fetchGAccountData end");
        setLoading(false);
        console.log("1232", fetchGAccountData);
      }
      getGoogleProfile();

      // Further processing of the access token can be done here
    } catch (error) {
      console.log(
        "Error exchanging authorization code for token:",
        error.response.data
      );
    }
  };

  // function saveIntoLocoServer(data) {
  //   console.log("saveIntoLocoServer");
  //   const accountId = "accounts/" + data.id;
  //   console.log("accountId", accountId);
  //   const request = {
  //     access_token: localStorage.getItem("token"),
  //     refresh_token: refreshToken,
  //     accountId: accountId,
  //     urlData: value,
  //   };
  //   try {
  //     axios
  //       .post("http://localhost:3000/api/auth", request, {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       })
  //       .then((res) => {
  //         console.log("Token saved successfully", res.data);
  //         // setProfileData(res.data.accounts);

  //         return res.data;
  //       })
  //       .catch((e) => e);
  //   } catch (e) {
  //     console.log("error", e);
  //   }
  // }

  const getGoogleProfile = async () => {
    try {
      const response = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${localStorage.getItem(
          "token"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
        }
      );

      const userProfile = response.data;
      setProfile(userProfile);
    } catch (error) {
      console.log("Error fetching Google profile:", error);
    }
  };

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    localStorage.removeItem("token");
    setProfile(null);
  };

  return (
    <div>
      {/* <GoogleFileUploadDrive /> */}
      <br />
      <br />
      {profile ? (
        <div>
          <img src={profile.picture} alt="user image" />
          <h3>You Logged in as</h3>
          <p>Name: {profile.name}</p>
          <p>Email Address: {profile.email}</p>
          <br />
          <br />
          <button onClick={logOut}>Log out</button>
        </div>
      ) : (
        <button onClick={() => login()}>Sign in with gmail</button>
      )}

      {loading ? (
        <Dialog
          open={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box sx={{ display: "flex", justifyContent: "normal" }}>
            <CircularProgress />
            <span>
              {" "}
              Wait a momment , Your patience is greatly appreciated!{"   "}
            </span>
          </Box>
        </Dialog>
      ) : null}
    </div>
  );
}
export default LoginGoogle;
