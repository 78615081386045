import React from "react";
import { Button } from "@mui/material";

const CustomButton = (props) => {
  const btnName = props.name;
  const btnClick = props.btnClick;
  return (
    <div>
      <Button onClick={btnClick} variant="contained">
        {btnName}
      </Button>
    </div>
  );
};

export default CustomButton;
