import React from "react";
//import CChart from "react-chartjs";
import { CChart } from "@coreui/react-chartjs";
import { Chart } from "chart.js";

const PieChart = (props) => {
  console.log("props", props);

  let veri = props.props[0].verified;
  let unVeri = props.props[0].unverify;
  console.log("veri", veri);
  return (
    <div>
      <CChart
        type="doughnut"
        data={{
          labels: [
            "Verified Profile" + ":" + veri + "%",
            "UnVerified Profile" + ":" + unVeri + "%",
          ],
          datasets: [
            {
              backgroundColor: ["#41B883", "#E46651"],
              data: [veri, unVeri],
              titleAlign: "center",
              xAlign: "right",
              position: "nearest",
            },
          ],
        }}
      />
    </div>
  );
};
export default PieChart;
