import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  red: {
    color: "red",
  },
});

const columns = [
  { field: "id", headerName: "S.no", width: 100, headerAlign: "center" },
  {
    field: "accountName",
    headerName: "Account Name",
    width: 300,
    headerAlign: "center",
  },
  { field: "name", headerName: "Account", width: 300, headerAlign: "center" },
  { field: "type", headerName: "Type", width: 200, headerAlign: "center" },
  {
    field: "role",
    headerName: "Role",
    width: 200,
    headerAlign: "center",
  },
  /*
  {
    field: "action",
    headerName: "  Map to Account",
    sortable: false,
    width: 250,
    renderCell: ({ row }) => {
      if (row.name == "accounts/102510617109167087739") {
        return (
          <Button onClick={() => btnMapToBrandClicked(row)}>Map Account</Button>
        );
      } else {
        return <Button onClick={() => btnViewClicked(row)}>view</Button>;
      }
    },
  }, multiple button condition base*/

  {
    field: "action",
    headerName: "  Map to Account",
    sortable: false,
    width: 250,

    renderCell: ({ row }) => {
      return (
        <Button
          style={{
            height: 40,
            backgroundColor: "white",
            color: "#3a2debd6",
            fontSize: 16,
            fontFamily: "-moz-initial",
            borderBlockColor: "grey",
            borderBlockEndColor: "grey",
            borderBlockStartColor: "grey",
          }}
          onClick={() => btnMapToBrandClicked(row)}
        >
          View
        </Button>
      );
    },
  },
];
const btnMapToBrandClicked = (row) => {
  console.log("props123", row);
};

const btnViewClicked = (row) => {
  console.log("btnViewClicked", row);
};

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
];

export const LocationData = (props) => {
  const navigation = useNavigate();
  console.log("props==", props.token);
  let index = 1;

  const handleEvent = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details // GridCallbackDetails
  ) => {
    console.log(`Accounts "${params.row.name}" clicked`);
  };

  const btnClicked = () => {};
  const newData = props.myLists.map((e) => {
    return {
      ...e,
      id: index++,
      //   action: { btnClicked },
    };
  });

  const navigateScreen = (params) => {
    console.log("newData = ", newData);

    // TO DO : ttsttiingg purpos only, we will change this to pass as a param from here to component

    localStorage.setItem("parent_A", params.row.name);

    navigation("/googlelocation", {
      state: {
        name: params.row.name,
        token: props.token,
        profileData: newData,
        selectdProfileItem: params.row,
      },
    });
  };
  console.log("newData", newData);
  const classes = useStyles();
  return (
    <div>
      <div
        style={{
          // justifyContent: "center",
          textJustify: "left",
          width: 400,
          // backgroundColor: "black",
          height: 60,
          //  alignItems: "center",
          marginLeft: 10,
          color: "black",
          textAlign: "left",
          borderRadius: 20,
          marginTop: 150,
          marginBottom: 0,
          fontSize: 30,
          fontFamily: "-moz-initial",
        }}
      >
        Google Profiles
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginTop: 0,
          height: "90%",
          width: "100%",
          marginLeft: 10,
        }}
      >
        <DataGrid
          //  onRowClick={handleEvent}
          // textAlign="right"

          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            // console.log(`Cell "${params.row.name}" clicked`);
            navigateScreen(params);
          }}
          rows={newData}
          columns={columns}
          initialState={{
            ...newData.initialState,
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          rowHeight={80}
          pageSizeOptions={[5, 10]}
          disableMultipleRowSelection="true"
          getRowClassName={(params) => {
            // console.log("params.role", params);
            // return params.row.role === "PRIMARY_OWNER" ? classes.red : "";
          }}
          getCellClassName={(params) => {
            // console.log("params.cell", params);
            if (params.field === "role") {
              return params.row.role === "PRIMARY_OWNER" ? classes.red : "";
            }
          }}
        />
      </div>

      <div> </div>
    </div>
  );
};
