import React from "react";
import axios from "axios";

export const GoogleProfilesUsersList = async (profileId) => {
  let urlString =
    "https://mybusinessaccountmanagement.googleapis.com/v1/" +
    profileId + // profileId
    "/admins";

  //console.log("profileId=", profileId);

  try {
    const res = await axios.get(urlString, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log(" Location Admin Profile", res.data);

    if ("admins" in res.data) {
      return res.data.admins;
    } else {
      return [];
    }
  } catch (e) {
    console.log("1234");
  }
};

export const DccProfilesUsersList = async (profileId) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LOCOWIZ_URL +
        "/api/dcc-profiles" +
        "?pid=" +
        profileId,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    console.log("DCC Group fetched successfully", response.data);
    return response.data;
  } catch (error) {
    console.log("DCC Group fetched successfully", error);
    throw error;
  }
};
