import * as React from "react";
import { useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import ListItem from "@mui/material/ListItem";

import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
// import { LocationData } from "../components/LocationData";
import { GoogleLogin } from "../components/GoogleLogin";
import LoginGoogle from "../LoginGoogle";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import GoogleIcon from "@mui/icons-material/Google";
import ManageGoogleUsers from "../Screen/ManageGoogleUsers";
import GoogleLocation from "../components/GoogleLocation";

import { useState } from "react";
import { useParams } from "react-router-dom";
// import { createContext } from "react";
// const urlPathContext = createContext();
import urlPathContext from "../Conntext/UrlPathContext";
import { fontSize } from "@mui/system";
import axios from "axios";

const drawerWidth = 240;

function DashBoard(props) {
  const { brand } = useParams();
  const { accountId } = useParams();

  const contextValue = {
    brand: brand,
    accountId: accountId,
  };

  console.log("brand", { brand });
  console.log("accountId", { accountId });

  const [component, setComponent] = useState("LoginGoogle");

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(true);
  const [gmbopen, setGmbOpen] = React.useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchDCCredentials();
        // Handle the received data
        localStorage.setItem("DCC-BRANDID", data.brand.brand_id);
        console.error("Success to fetch DC credentials:", data.brand);

        console.log(data);
      } catch (error) {
        localStorage.setItem("DCC-BRANDID", []);
        // Handle the error (e.g., show an error message to the user)
        console.error("Failed to fetch DC credentials:", error);
      }
    };

    fetchData();
  }, []); // Add specific dependencies if needed

  const fetchDCCredentials = async () => {
    const request = {
      apikey: "c4bd723777d645879a6737045bb2148d",
    };

    const url = process.env.REACT_APP_GET_BRANDID_URL;
    console.log("url dcc", url);
    try {
      const response = await axios.get(url, {
        params: request,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch DC credentials:", error);
      throw error; // Rethrow the error to be handled by the caller
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleGmbClick = () => {
    setGmbOpen(!gmbopen);
    setComponent("LoginGoogle");
  };

  const businessBtnClicked = () => {
    console.log("Business Buttton Clicked");
  };
  const drawer = (
    <div style={{ backgroundColor: "#3386FF" }}>
      <Toolbar />
      <Divider />
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {/* Nested List Items */}
          </ListSubheader>
        }
      >
        {/* <ListItemButton onClick={() => setComponent("")}>
          <ListItemIcon>
            <WorkOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Businesses" />
        </ListItemButton> */}

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <PublishedWithChangesIcon />
          </ListItemIcon>
          <ListItemText primary="Publisher" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4 }} onClick={handleGmbClick}>
              <ListItemIcon>
                <GoogleIcon />
              </ListItemIcon>
              <ListItemText primary="GBP" />
              {gmbopen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {/*  Adding sub button    */}
            <Collapse in={gmbopen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  sx={{ pl: 6 }}
                  // onClick={() => setComponent("GoogleLogin")} ///  account Profille list table
                  onClick={() => setComponent("GoogleLocation")} ///  account Profille list table
                >
                  <ListItemIcon>
                    <WorkOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Groups & Profiles" />
                </ListItemButton>
                {/* // add button here */}
                {/* <ListItemButton
                  sx={{ pl: 6 }}
                  onClick={() => setComponent("ManageGoogleUsers")}
                >
                  <ListItemIcon>
                    <WorkOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary="Manage Users" />
                </ListItemButton> */}
                {/* // End add button here */}
              </List>
            </Collapse>{" "}
            {/*  End Adding sub button    */}
          </List>
        </Collapse>
      </List>
      <Divider />
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },

          backgroundColor: "#3a2debd6",
          backgroundColor: "#3386FF",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h3" noWrap component="div">
            LocoWiz
          </Typography>
        </Toolbar>
      </AppBar> */}
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        // component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <urlPathContext.Provider value={contextValue}>
          {component === "LoginGoogle" ? <LoginGoogle /> : <null />}
          {/* {component === "GoogleLogin" ? <GoogleLogin /> : <null />} */}
          {component === "GoogleLocation" ? <GoogleLocation /> : <null />}
          {component === "ManageGoogleUsers" ? <ManageGoogleUsers /> : <null />}
        </urlPathContext.Provider>
        <Toolbar />
        {/* <Typography paragraph>LocoWiz</Typography>
        <Typography paragraph>LocoWiz .</Typography> */}
      </Box>
    </Box>
  );
}

DashBoard.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DashBoard;
